import React from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { isAvatarImageAvailable } from "../../util";

const ConnectionRequests = ({
  connectionRequests,
  handleConnectionRequest,
}) => {
  return (
    <div className="py-4 px-2 md:-mt-12 md:ml-2">
      {connectionRequests.length === 0 ? (
        <p className="text-center mt-4 mb-4">
          No more connection requests available.
        </p>
      ) : (
        connectionRequests.map((request) => (
          <div
            key={request._id}
            className="flex items-center bg-white rounded-lg mx-3 md:mx-0 shadow-md py-4 md:py-8 px-3 md:px-0 mb-4 md:w-[40rem] gap-2"
          >
            {isAvatarImageAvailable(request.sender.startupimage) ? (
              <img
                className="h-16 w-16 rounded-full mr-4"
                src={request.sender.startupimage}
                alt="avatar"
              />
            ) : (
              <AlphabetAvatar
                alphabet={request.sender.firstName.charAt(0)}
                className="h-16 w-16 rounded-full mr-4"
              />
            )}
            <div className="flex-1">
              <h2 className="text-base md:text-2xl font-medium capitalize mb-2 md:!mb-4 ">
                {request.sender.firstName}
              </h2>
              <Link
                className="text-sm md:text-base border-2 border-black bg-white text-black md:!p-2 p-1 rounded"
                to={"/investor/startupdetails/" + request.sender._id}
              >
                View Profile
              </Link>
            </div>

            <div className="flex space-x-2">
              {/* Icon buttons for mobile */}
              <div className="md:hidden">
                <IconButton
                  aria-label="accept"
                  onClick={() => handleConnectionRequest(request._id, true)}
                >
                  <CheckCircleIcon
                    fontSize="large"
                    className="text-skincolor"
                  />
                </IconButton>
                <IconButton
                  aria-label="deny"
                  onClick={() => handleConnectionRequest(request._id, false)}
                >
                  <CancelIcon fontSize="large" className="text-deny" />
                </IconButton>
              </div>

              {/* Regular buttons for desktop */}
              <div className="hidden md:flex justify-center w-full">
                <button
                  className="bg-skincolor text-black px-2 py-1 rounded w-20 h-10"
                  onClick={() => handleConnectionRequest(request._id, true)}
                >
                  Accept
                </button>
                <button
                  className="bg-deny text-black px-2 py-1 rounded w-20 h-10 ml-2"
                  onClick={() => handleConnectionRequest(request._id, false)}
                >
                  Deny
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ConnectionRequests;
