import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Formik } from "formik";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import {
  startupIndustries,
  startupStages,
  introAIOptions,
} from "../../Constants";
import { countries } from "../commons/countries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const interestOptions = [
  "Government",
  "Discovery",
  "Manufacturing",
  "Marketplace",
  "Mobile",
  "Platform",
  "Consulting",
  "Engineering",
  "Others",
  "Rental",
  "Research",
];

const Schema1 = Yup.object().shape({
  startupimage: Yup.mixed().required("Your profile image is required"),
  firstName: Yup.string().min(2, "Too Short!").required("Name is required"),
  interests: Yup.array()
    .min(1, "At least one interest is required")
    .required("Please select at least one interest"),
});

const Schema3 = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  tel: Yup.string().max(10).required("contact number is required"),
});

const Schema5 = Yup.object().shape({
  intro_message: Yup.string()
    .required("Intro Message is required")
    .test(
      "wordCount",
      "Intro Message cannot exceed 50 words",
      (value) => value && value.trim().split(/\s+/).length <= 50,
    ),
  interested_in: Yup.array().min(1, "Select at least one interest"),
  strongest_skills: Yup.array().min(1, "Select at least one strongest skill"),
  years_of_experience: Yup.string().required("Experience is required"),
  support_needed: Yup.array().min(1, "Select at least one support area"),
});

const Register = ({ onUpdate, activeSubTab }) => {
  const url = app_config.apiurl;
  const [activeTab, setActiveTab] = useState("intro");

  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = JSON.parse(sessionStorage.getItem("startup"));

    return savedUser
      ? {
          ...savedUser,
          interests: Array.isArray(savedUser.interests)
            ? savedUser.interests
            : savedUser.interests
              ? savedUser.interests.split(", ")
              : [],
        }
      : {};
  });

  //const [selimage, setSelimage] = useState("");

  const [startUpImage, setStartUpImage] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (activeSubTab) {
      setActiveTab(activeSubTab);
    }
  }, [activeSubTab]);

  const updateUser = async (values, { setSubmitting }) => {
    console.log("control");
    // Convert selected interests array to a comma-separated string
    values.interests = Array.isArray(values.interests)
      ? values.interests.join(", ")
      : values.interests;

    // Ensure other multi-select values are properly formatted (in array form)
    values.interested_in = Array.isArray(values.interested_in)
      ? values.interested_in
      : [];
    values.strongest_skills = Array.isArray(values.strongest_skills)
      ? values.strongest_skills
      : [];
    values.support_needed = Array.isArray(values.support_needed)
      ? values.support_needed
      : [];

    if (startUpImage) {
      let url = await uploadImage(startUpImage);
      values.startupimage = url;
    }
    if (documentFile) {
      let url = await uploadImage(documentFile);
      values.documents = url;
    }

    console.log(values, "values");

    setSubmitting(true);
    const res = await fetch(
      `${app_config.apiurl}/users/update/${currentUser._id}`,
      {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log(res.status);
    setSubmitting(false);

    if (res.status === 200) {
      const data = await res.json();
      // Ensure new interests are formatted as an array for further use
      const updatedUser = {
        ...data.result,
        interests: data.result.interests
          ? data.result.interests.split(", ")
          : [],
      };
      setCurrentUser(updatedUser);
      sessionStorage.setItem("startup", JSON.stringify(updatedUser));
      onUpdate();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Saved Successfully",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill the required details",
      });
    }
  };
  const uploadImage = async (file) => {
    const fd = new FormData();
    fd.append("myfile", file);
    const res = await fetch(url + "/util/uploadfile", {
      method: "POST",
      body: fd,
    });
    let response = await res.json();
    return res.status === 200 ? response.url : null;
  };

  const handleFileChange = (setter, e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file && file.size > maxSizeInBytes) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "File size exceeds the maximum limit of 5MB.",
      });
    } else {
      setter(file);
    }
  };

  return (
    <>
      <div className="md:ml-6 md:mx-12 mx-8 md:!mt-0 ">
        {/* <!-- Tabs navs --> */}
        <div className="flex justify-start mb-4 -ml-4 md:ml-0 mt-2 md:-mt-2">
          <button
            className={`px-2 py-2 rounded-l-full text-xs min-w-[100px] md:min-w-[140px] ${
              activeTab === "intro"
                ? "bg-black text-white"
                : "bg-white text-black border-2 border-black"
            }`}
            onClick={() => setActiveTab("intro")}
            role="tab"
            aria-controls="ex3-tabs-1"
            aria-selected={activeTab === "intro"}
          >
            Intro AI
          </button>
          <button
            className={`px-2 py-2 text-xs min-w-[100px] md:min-w-[140px] border-l-0 ${
              activeTab === "basic"
                ? "bg-black text-white"
                : "bg-white text-black border-2 border-black"
            }`}
            onClick={() => setActiveTab("basic")}
            role="tab"
            id="ex3-tabs-2"
            aria-controls="ex3-tabs-2"
            aria-selected={activeTab === "basic"}
          >
            Basic Info
          </button>
          <button
            className={`px-2 py-2 rounded-r-full text-xs md:min-w-[140px] border-l-0 ${
              activeTab === "personal"
                ? "bg-black text-white"
                : "bg-white text-black border-2 border-black"
            }`}
            onClick={() => setActiveTab("personal")}
            role="tab"
            aria-controls="ex3-tabs-4"
            aria-selected={activeTab === "personal"}
          >
            Personal Details
          </button>
        </div>
        {/* <!-- Tabs navs --> */}

        {/* <!-- Tabs content --> */}
        <div className="tab-content " id="ex2-content">
          {/* first form  */}
          {activeTab === "basic" && (
            <div
              className="tab-pane fade show active"
              id="ex3-tabs-1"
              role="tabpanel"
              aria-labelledby="ex3-tab-1"
            >
              <div className="tab-content mb-5 mr-5" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-About Startup"
                  role="tabpanel"
                  aria-labelledby="v-pills-About Startup-tab"
                >
                  <Formik
                    initialValues={{
                      ...currentUser,
                      startupStages: currentUser.startupStages || "", // Set to an empty string if no value
                      startupName: currentUser.startupName || "",
                    }}
                    validationSchema={Schema1}
                    onSubmit={updateUser}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="md:w-[100%]">
                        <div className="form-outline mb-2">
                          <div className="file-upload-wrapper">
                            <div className="image-body mt-6 md:mt-0 ml-2">
                              <label
                                htmlFor="startupimage"
                                className="text-base text-black mb-2"
                              >
                                Your profile Image
                              </label>
                              <br />
                              <div className="relative flex items-center justify-center w-20 h-20 rounded-full border-2 border-gray-300 bg-gray-100 overflow-hidden cursor-pointer">
                                {startUpImage || currentUser.startupimage ? (
                                  <img
                                    src={
                                      startUpImage
                                        ? URL.createObjectURL(startUpImage)
                                        : currentUser.startupimage
                                    }
                                    alt="Your profile Image"
                                    className="w-full h-full object-cover object-top rounded-full"
                                  />
                                ) : (
                                  <img
                                    src="/AvatarPlaceholder.svg"
                                    alt="Avatar Placeholder"
                                    className="w-[85%] h-[85%]"
                                  />
                                )}
                                <input
                                  type="file"
                                  name="startupimage"
                                  className="absolute inset-0 opacity-0 cursor-pointer"
                                  onChange={(e) =>
                                    handleFileChange(setStartUpImage, e)
                                  }
                                  accept="image/*"
                                />
                              </div>

                              {/* Dynamic text for upload and re-upload */}
                              <p className="mt-2 text-sm font-medium text-gray-500">
                                {startUpImage || currentUser.startupimage
                                  ? "*Click to Re-upload Image"
                                  : "*Click to Upload Image"}
                              </p>

                              {errors.startupimage && touched.startupimage ? (
                                <div className="text-deny">
                                  {errors.startupimage}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6">
                          <div className="form-outline mt-4 ms-1">
                            <MDBInput
                              label="Your Name"
                              id="firstName"
                              type="text"
                              value={values.firstName}
                              onChange={handleChange}
                              name="firstName"
                            />
                            {errors.firstName && touched.firstName ? (
                              <div className="text-deny">
                                {errors.firstName}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-outline mt-4 ms-1">
                            <MDBInput
                              label="Current Role"
                              id="currentRole"
                              type="text"
                              required
                              value={values.currentRole}
                              onChange={handleChange}
                              name="currentRole"
                            />
                            {errors.currentRole && touched.currentRole ? (
                              <div className="text-deny">
                                {errors.currentRole}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-outline mt-4 ms-1">
                            <MDBInput
                              label="Your Education"
                              id="education"
                              type="text"
                              required
                              value={values.education}
                              onChange={handleChange}
                              name="education"
                            />
                            {errors.education && touched.education ? (
                              <div className="text-deny">
                                {errors.education}
                              </div>
                            ) : null}
                          </div>

                          {/* <div className="form-outline mt-4 mb-4 ms-1">
                            <MDBInput
                              label="Startup Name"
                              id="startupName"
                              type="text"
                              value={values.startupName}
                              onChange={handleChange}
                              name="startupName"
                            />
                            {errors.startupName && touched.startupName ? (
                              <div className="text-deny">
                                {errors.startupName}
                              </div>
                            ) : null}
                          </div> */}

                          {/* <div className="form-outline mb-2 ms-1">
                            <MDBInput
                              label="Website "
                              type="url"
                              id="website"
                              value={values.website}
                              onChange={handleChange}
                              name="website"
                            />
                          </div> */}
                          <div className="form-outline mt-4 mb-2 ms-1">
                            <MDBInput
                              label="Github Link"
                              type="url"
                              id="website"
                              value={values.githubLink}
                              onChange={handleChange}
                              name="githubLink"
                            />
                          </div>

                          {/* <div className="form-outline mt-4 ms-1">
                            <MDBInput
                              label="Amount Raising"
                              id="currentRaiseAmount"
                              type="number"
                              value={values.currentRaiseAmount}
                              onChange={handleChange}
                              name="currentRaiseAmount"
                            />
                          </div> */}

                          <div className="mb-4 mt-4 ml-1">
                            <label htmlFor="select1">Your Industry</label>
                            <br />
                            <select
                              className="select border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500 w-full"
                              name="industry"
                              value={values.industry}
                              onChange={handleChange}
                            >
                              <option defaultChecked="true">
                                Select Industry
                              </option>
                              {startupIndustries.map((ind) => (
                                <option key={ind.value} value={ind.value}>
                                  {ind.text}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-12 mb-4 ml-1">
                            <label htmlFor="interests" className="mb-2">
                              Interests
                            </label>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="interests"
                              options={interestOptions}
                              disableCloseOnSelect
                              value={
                                Array.isArray(values.interests)
                                  ? values.interests
                                  : values.interests
                                    ? values.interests.split(", ")
                                    : ""
                              }
                              onChange={(event, newValue) => {
                                setFieldValue("interests", newValue);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Interests"
                                  placeholder="Select Interests"
                                />
                              )}
                            />
                            {touched.interests && errors.interests && (
                              <div className="text-deny mt-1">
                                {errors.interests}
                              </div> // Custom error message
                            )}
                          </div>
                        </div>

                        {/* <FormControl className="form-outline mt-4 ms-1">
                          <FormLabel id="demo-radio-buttons-group-label">
                            Funded Or Bootstrapped
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="none"
                            name="type"
                            id="type"
                            value={values.type}
                            onChange={handleChange}
                          >
                            <div className="form-outline ">
                              <FormControlLabel
                                value="Funded"
                                control={<Radio />}
                                label="Funded"
                              />
                              <FormControlLabel
                                value="Bootstrapped"
                                control={<Radio />}
                                label="Bootstrapped"
                              />
                            </div>
                          </RadioGroup>
                        </FormControl> */}

                        {/* <FormControl className="form-outline mt-4 ms-1">
                          <FormLabel id="startup-stages-label">
                            Startup Stage
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="startup-stages-label"
                            name="startupStages"
                            value={values.startupStages}
                            onChange={(e) =>
                              setFieldValue("startupStages", e.target.value)
                            }
                          >
                            {startupStages.map((stage) => (
                              <FormControlLabel
                                key={stage.value}
                                value={stage.value}
                                control={<Radio />}
                                label={stage.text}
                              />
                            ))}
                          </RadioGroup>
                          {errors.startupStages && touched.startupStages ? (
                            <div className="text-deny">
                              {errors.startupStages}
                            </div>
                          ) : null}
                        </FormControl> */}

                        <div className="form-outline mt-4 mb-2"></div>

                        <div className="w-full flex justify-center">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="btn btn-block mb-1 w-[60%] mx-auto"
                            style={{
                              backgroundColor: "#b0ff4b",
                              color: "black",
                            }}
                          >
                            {isSubmitting ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
          {/* second form  */}
          {activeTab === "intro" && (
            <div
              className="tab-pane fade  show active"
              id="ex3-tabs-2"
              role="tabpanel"
              aria-labelledby="ex3-tab-2"
            >
              <Formik
                initialValues={{
                  intro_message: currentUser.intro_message || "",
                  interested_in: currentUser.interested_in || [],
                  strongest_skills: currentUser.strongest_skills || [],
                  years_of_experience: currentUser.years_of_experience || "",
                  support_needed: currentUser.support_needed || [],
                  programming_skills: currentUser.programming_skills || [],
                  networking_goals: currentUser.networking_goals || [],
                }}
                validationSchema={Schema5}
                onSubmit={(values, { setSubmitting }) => {
                  updateUser(values, { setSubmitting });
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <p className="max-w-2xl w-full  mb-4 text-sm font-extralight">
                      Change settings here & check back every monday and
                      thursday to see new intros. Your intros will match based
                      on the settings you change below.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2  gap-1">
                      <div className="col-12 mb-4">
                        <label htmlFor="interested_in" className="mb-2">
                          What are you interested in?
                        </label>
                        <Autocomplete
                          multiple
                          limitTags={1}
                          id="interested_in"
                          options={introAIOptions.interests}
                          disableCloseOnSelect
                          value={values.interested_in}
                          onChange={(event, newValue) => {
                            setFieldValue("interested_in", newValue);
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Interests"
                              size="small"
                              sx={{
                                width: 305,

                                "& .MuiInputLabel-root": {
                                  fontSize: "0.875rem",
                                },
                              }}
                            />
                          )}
                        />
                        {touched.interested_in && errors.interested_in && (
                          <div className="text-deny mt-1">
                            {errors.interested_in}
                          </div>
                        )}
                      </div>

                      {/* Strongest Skills */}
                      <div className="col-12 mb-4">
                        <label htmlFor="strongest_skills" className="mb-2">
                          What are your strongest skills? (Select up to 2)
                        </label>
                        <Autocomplete
                          multiple
                          limitTags={1}
                          id="strongest_skills"
                          options={introAIOptions.strongest_skills}
                          disableCloseOnSelect
                          value={values.strongest_skills}
                          onChange={(event, newValue) => {
                            if (newValue.length <= 2) {
                              setFieldValue("strongest_skills", newValue);
                            } else {
                              Swal.fire({
                                icon: "error",
                                title: "Limit Exceeded",
                                text: "You can select up to 2 strongest skills only.",
                              });
                            }
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Strongest Skills"
                              size="small"
                              sx={{
                                width: 305,
                                "& .MuiInputLabel-root": {
                                  fontSize: "0.875rem",
                                },
                              }}
                            />
                          )}
                        />
                        {touched.strongest_skills &&
                          errors.strongest_skills && (
                            <div className="text-deny mt-1">
                              {errors.strongest_skills}
                            </div>
                          )}
                      </div>

                      {/* Experience */}
                      <div className="col-12 mb-4">
                        <label htmlFor="years_of_experience" className="mb-2">
                          How many years of experience do you have?
                        </label>
                        <br />
                        <select
                          className="select border w-[90%] border-gray-300 rounded-md px-2 py-2.5 focus:outline-none focus:border-blue-500"
                          name="years_of_experience"
                          value={values.years_of_experience}
                          onChange={handleChange}
                        >
                          <option defaultChecked="true">
                            Select Experience
                          </option>
                          {introAIOptions.experience.map((exp) => (
                            <option key={exp} value={exp}>
                              {exp}
                            </option>
                          ))}
                        </select>
                        {touched.years_of_experience &&
                          errors.years_of_experience && (
                            <div className="text-deny mt-1">
                              {errors.years_of_experience}
                            </div>
                          )}
                      </div>

                      {/* Support Needed */}
                      <div className="col-12 mb-4">
                        <label htmlFor="support_needed" className="mb-2">
                          What support do you need?
                        </label>
                        <Autocomplete
                          multiple
                          limitTags={1}
                          id="support_needed"
                          options={introAIOptions.support_needed}
                          disableCloseOnSelect
                          value={values.support_needed}
                          onChange={(event, newValue) => {
                            setFieldValue("support_needed", newValue);
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Support Areas"
                              size="small"
                              sx={{
                                width: 305,

                                "& .MuiInputLabel-root": {
                                  fontSize: "0.875rem",
                                },
                              }}
                            />
                          )}
                        />
                        {touched.support_needed && errors.support_needed && (
                          <div className="text-deny mt-1">
                            {errors.support_needed}
                          </div>
                        )}
                      </div>

                      {/* Programming SKills */}
                      <div className="col-12 mb-4">
                        <label htmlFor="programming_skills" className="mb-2">
                          What Technical Skills you know?
                        </label>
                        <Autocomplete
                          multiple
                          limitTags={1}
                          id="programming_skills"
                          options={introAIOptions.programmingSkills}
                          disableCloseOnSelect
                          value={values.programming_skills}
                          onChange={(event, newValue) => {
                            setFieldValue("programming_skills", newValue);
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Technical Skills"
                              size="small"
                              sx={{
                                width: 305,

                                "& .MuiInputLabel-root": {
                                  fontSize: "0.875rem",
                                },
                              }}
                            />
                          )}
                        />
                        {touched.programming_skills &&
                          errors.programming_skills && (
                            <div className="text-deny mt-1">
                              {errors.programming_skills}
                            </div>
                          )}
                      </div>

                      {/* Networking Goals */}
                      <div className="col-12 mb-4">
                        <label htmlFor="networking_goals" className="mb-2">
                          What are your Networking Goals?
                        </label>
                        <Autocomplete
                          multiple
                          limitTags={1}
                          id="networking_goals"
                          options={introAIOptions.networkingGoals}
                          disableCloseOnSelect
                          value={values.networking_goals}
                          onChange={(event, newValue) => {
                            setFieldValue("networking_goals", newValue);
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Networking Goals"
                              size="small"
                              sx={{
                                width: 305,

                                "& .MuiInputLabel-root": {
                                  fontSize: "0.875rem",
                                },
                              }}
                            />
                          )}
                        />
                        {touched.networking_goals &&
                          errors.networking_goals && (
                            <div className="text-deny mt-1">
                              {errors.networking_goals}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="col-12 mb-4 w-80 md:w-full ">
                      <label htmlFor="intro_message" className="mb-2">
                        Intro Message
                      </label>
                      <MDBTextArea
                        id="intro_message"
                        name="intro_message"
                        rows={4}
                        placeholder="Write your intro message (max 50 words)"
                        value={values.intro_message}
                        onChange={(e) => {
                          handleChange(e);
                          // Optional: Set custom logic to update state if needed
                        }}
                        className={`form-control ${
                          touched.intro_message && errors.intro_message
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.intro_message && errors.intro_message && (
                        <div className="text-deny mt-1">
                          {errors.intro_message}
                        </div>
                      )}
                      {/* Word count display */}
                      <div className="mt-1 text-sm text-gray-600">
                        Word count:{" "}
                        {
                          values.intro_message
                            .trim()
                            .split(/\s+/)
                            .filter(Boolean).length
                        }{" "}
                        / 50
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div className="w-full flex justify-center">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn w-[60%]  mb-10"
                        style={{ backgroundColor: "#b0ff4b", color: "black" }}
                      >
                        {isSubmitting ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
          {/* third form  */}
          {activeTab === "personal" && (
            <div
              className="tab-pane fade show active"
              id="ex3-tabs-4"
              role="tabpanel"
              aria-labelledby="ex3-tab-4"
            >
              <div className="tab-content mb-5 mt-10" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-Owner And Product Details"
                  role="tabpanel"
                  aria-labelledby="v-pills-Owner And Product Details-tab"
                >
                  <Formik
                    initialValues={currentUser}
                    validationSchema={Schema3}
                    onSubmit={updateUser}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      errors,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row mb-1 form-floating ">
                          <div className="col">
                            <div className="form-outline mb-4">
                              <MDBInput
                                label="Email Address"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                name="email"
                              />
                              {errors.email && touched.email ? (
                                <div className="text-deny">{errors.email}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-4">
                              <MDBInput
                                label=" Mobile"
                                type="tel"
                                id="tel"
                                value={values.tel}
                                onChange={handleChange}
                                name="tel"
                                maxLength={10}
                                minLength={10}
                                pattern="[0-9]*"
                                placeholder="Enter 10 digit mobile number"
                              />
                              {errors.tel ? (
                                <div className="text-deny">{errors.tel}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-1 form-floating">
                          <div className="col">
                            <div className="col">
                              <div className="form-outline mb-4">
                                <select
                                  id="location"
                                  onChange={handleChange}
                                  value={values.location}
                                  className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                  <option value="">Location</option>
                                  {countries.map((option) => {
                                    return (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2 form-floating">
                          <div className="col">
                            <div className="col">
                              <div className="form-outline mb-4">
                                <MDBInput
                                  label="LinkedIn Profile"
                                  type="url"
                                  id="linkedInUrl"
                                  value={values.linkedInUrl}
                                  onChange={handleChange}
                                  name="linkedInUrl"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full flex justify-center">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="btn btn-block mb-1 w-[60%]"
                            style={{
                              backgroundColor: "#b0ff4b",
                              color: "black",
                            }}
                          >
                            {isSubmitting ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
