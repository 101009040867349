import React from "react";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Swal from "sweetalert2";
import { introAIOptions } from "../../Constants";

// Define icons for checkbox states
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Step2InterestsSupportStrong = ({
  values,
  setFieldValue,
  errors,
  touched,
}) => {
  return (
    <>
      <div className="col-12 mb-4 mt-16">
        <label
          htmlFor="interested_in"
          className="mb-2 font-semibold text-black"
        >
          What are you interested in?
        </label>
        <Autocomplete
          multiple
          limitTags={1}
          id="interested_in"
          options={introAIOptions.interests}
          disableCloseOnSelect
          value={values.interested_in}
          onChange={(event, newValue) => {
            setFieldValue("interested_in", newValue);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Interests"
            />
          )}
        />
      </div>

      <div className="col-12 mb-4 mt-4 md:mt-0">
        <label
          htmlFor="support_needed"
          className="mb-2 font-semibold text-black"
        >
          What support do you need?
        </label>
        <Autocomplete
          multiple
          limitTags={1}
          id="support_needed"
          options={introAIOptions.support_needed}
          disableCloseOnSelect
          value={values.support_needed}
          onChange={(event, newValue) => {
            setFieldValue("support_needed", newValue);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Support Areas"
            />
          )}
        />
      </div>

      <div className="col-12 mb-4">
        <label
          htmlFor="strongest_skills"
          className="mb-2 font-semibold text-black"
        >
          What are your strongest skills? (Select up to 2)
        </label>
        <Autocomplete
          multiple
          limitTags={1}
          id="strongest_skills"
          options={introAIOptions.strongest_skills}
          disableCloseOnSelect
          value={values.strongest_skills}
          onChange={(event, newValue) => {
            if (newValue.length <= 2) {
              setFieldValue("strongest_skills", newValue);
            } else {
              Swal.fire({
                icon: "error",
                title: "Limit Exceeded",
                text: "You can select up to 2 strongest skills only.",
              });
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Strongest Skills"
            />
          )}
        />
      </div>
    </>
  );
};

export default Step2InterestsSupportStrong;
