import classNames from "classnames";
import React from "react";

const AlphabetAvatar = ({ className, alphabet }) => {
  const defaultClass =
    "flex items-center justify-center w-16 h-16 md:w-24 md:h-24 rounded-full bg-basecolor text-white";
  return (
    <div className={classNames(defaultClass, className)}>
      <span className="text-xl md:text-3xl">{alphabet.toUpperCase()}</span>
    </div>
  );
};

export default AlphabetAvatar;
