import React, { useState } from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton, CircularProgress } from "@mui/material";
import { isAvatarImageAvailable } from "../../util.js";
import { FaCheckCircle } from "react-icons/fa";

const MyNetwork = ({
  approvedRequests,
  pendingRequests,
  invitations,
  handleConnectionRequest,
  currentApprovedRequestPage,
  setCurrentApprovedRequestPage,
  totalApprovedRequestPages,
  loadingApprovedRequest,
}) => {
  const [activeTab, setActiveTab] = useState("approved");

  const renderApprovedTab = () => {
    if (loadingApprovedRequest) {
      return (
        <div className="text-center w-full mt-64">
          <CircularProgress color="success" />
        </div>
      );
    }

    return (
      <div className="py-2 mb-4 -mt-8 md:-mt-12  md:!py-10 ">
        {renderPagination()}
        {approvedRequests.length === 0 ? (
          <p className="text-center mt-16 md:mt-12 mb-4">
            Yet to create your network.
          </p>
        ) : (
          approvedRequests.map((request, i) => {
            let avatar;
            let navlink;
            if (request.role === "startup") {
              avatar = request.startupimage;
              navlink = `/startup/introai/${request._id}`;
            } else if (request.role === "investor") {
              avatar = request.investoravatar;
              navlink = `/startup/investordetails/${request._id}`;
            }

            return (
              <div
                key={request._id}
                className="flex flex-col   md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center mr-4">
                      {isAvatarImageAvailable(avatar) ? (
                        <img
                          className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                          src={avatar}
                          alt="avatar"
                        />
                      ) : (
                        <AlphabetAvatar
                          alphabet={request.firstName.charAt(0)}
                          className="!h-16 !w-16 md:h-16 md:w-16"
                        />
                      )}
                    </div>

                    <div className="flex gap-x-2 items-center">
                      <h2 className="text-base md:text-xl font-medium capitalize truncate max-w-[9rem] md:max-w-[20rem]">
                        {request.firstName}{" "}
                        {request.role === "investor" && request.lastName}
                      </h2>

                      {request.role === "startup" &&
                        request.verificationStatus === "verified" && (
                          <FaCheckCircle
                            className="text-green-600  "
                            size={"15px"}
                          />
                        )}
                    </div>
                  </div>

                  <div className="md:hidden flex-shrink-0 mr-3">
                    <Link
                      className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                      to={navlink}
                    >
                      <ArrowForwardIcon />
                    </Link>
                  </div>

                  <div className="hidden md:block mr-3">
                    <Link
                      className="bg-white border-2 border-black text-black p-2 rounded"
                      to={navlink}
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  const renderPendingTab = () => (
    <div className="py-2  mb-4 md:-mt-8   md:!py-10">
      {pendingRequests.length === 0 ? (
        <p className="text-center mt-8">No Pending requests</p>
      ) : (
        pendingRequests.map((request) => {
          let avatar;
          let navlink;
          if (request.role === "startup") {
            avatar = request.startupimage;
            navlink = `/startup/introai/${request._id}`;
          } else if (request.role === "investor") {
            avatar = request.investoravatar;
            navlink = `/startup/investordetails/${request._id}`;
          }
          return (
            <div
              key={request._id}
              className="flex flex-col   md:w-[40rem] gap-2 py-4 px-2 md:px-0 sm:p-6 bg-white mx-2 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex items-center mr-4">
                    {isAvatarImageAvailable(avatar) ? (
                      <img
                        className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                        src={avatar}
                        alt="avatar"
                      />
                    ) : (
                      <AlphabetAvatar
                        alphabet={request.firstName.charAt(0)}
                        className="!h-16 !w-16 md:h-16 md:w-16"
                      />
                    )}
                  </div>

                  <div className="flex gap-x-2 items-center">
                    <h2 className="text-base  md:text-xl font-medium capitalize truncate max-w-[9rem] md:max-w-[20rem]">
                      {request.firstName}{" "}
                      {request.role === "investor" && request.lastName}
                    </h2>

                    {request.role === "startup" &&
                      request.verificationStatus === "verified" && (
                        <FaCheckCircle
                          className="text-green-600  "
                          size={"15px"}
                        />
                      )}
                  </div>
                </div>

                <div className="md:hidden flex-shrink-0 mr-3">
                  <Link
                    className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7"
                    to={navlink}
                  >
                    <ArrowForwardIcon />
                  </Link>
                </div>

                <div className="hidden md:block mr-3">
                  <Link
                    className="bg-white border-2 border-black text-black p-2 rounded"
                    to={navlink}
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );

  const renderInvitationsTab = () => (
    <div className="py-2  mb-4 md:-mt-8  md:!py-10">
      {invitations.length === 0 ? (
        <p className="text-center mt-8">No Invitations</p>
      ) : (
        invitations.map((invitationData) => {
          let invitation = invitationData.sender;
          return (
            <div
              key={invitation._id}
              className="flex items-center bg-white rounded-lg mx-3 md:mx-0 shadow-md py-4 md:py-8 px-3 md:px-0 mb-4 md:w-[40rem] gap-2 ml-4"
            >
              {/* Profile Picture - Avatar */}
              {isAvatarImageAvailable(invitation.startupimage) ? (
                <img
                  className="h-16 w-16 rounded-full mr-4"
                  src={invitation.startupimage}
                  alt="avatar"
                />
              ) : (
                <AlphabetAvatar
                  alphabet={invitation.firstName.charAt(0)}
                  className="h-16 w-16 rounded-full mr-4"
                />
              )}

              {/* Name and View Profile */}
              <div className="flex-1 flex flex-col gap-y-3">
                <div className="flex gap-x-2 items-center">
                  <h2 className="text-sm md:text-xl font-medium capitalize truncate max-w-[6rem] md:max-w-[15rem]">
                    {invitation.firstName}
                  </h2>
                  {invitation.verificationStatus === "verified" && (
                    <FaCheckCircle className="text-green-600  " size={"16px"} />
                  )}
                </div>
                <Link
                  className="text-sm md:text-base border-2 border-black bg-white text-black md:!p-2 p-1 rounded w-fit"
                  to={"/startup/introai/" + invitation._id}
                >
                  View Profile
                </Link>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-3">
                {/* Icon buttons for mobile */}
                <div className="md:hidden">
                  <IconButton
                    aria-label="accept"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, true)
                    }
                  >
                    <CheckCircleIcon
                      fontSize="medium"
                      className="text-skincolor"
                    />
                  </IconButton>
                  <IconButton
                    aria-label="deny"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, false)
                    }
                  >
                    <CancelIcon fontSize="medium" className="text-deny" />
                  </IconButton>
                </div>

                {/* Regular buttons for desktop */}
                <div className="hidden md:flex justify-center">
                  <button
                    className="bg-skincolor text-black px-2 py-1 rounded w-20 h-10"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, true)
                    }
                  >
                    Accept
                  </button>
                  <button
                    className="bg-deny text-black px-2 py-1 rounded w-20 h-10 ml-2"
                    onClick={() =>
                      handleConnectionRequest(invitationData._id, false)
                    }
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );

  const renderPagination = () => {
    if (totalApprovedRequestPages <= 1) return null;

    const generatePageNumbers = () => {
      const visiblePages = [];
      if (totalApprovedRequestPages <= 5) {
        for (let i = 1; i <= totalApprovedRequestPages; i++)
          visiblePages.push(i);
      } else {
        visiblePages.push(1);
        if (currentApprovedRequestPage > 3) visiblePages.push("...");
        for (
          let i = Math.max(currentApprovedRequestPage - 1, 2);
          i <=
          Math.min(
            currentApprovedRequestPage + 1,
            totalApprovedRequestPages - 1,
          );
          i++
        ) {
          visiblePages.push(i);
        }
        if (currentApprovedRequestPage < totalApprovedRequestPages - 2)
          visiblePages.push("...");
        visiblePages.push(totalApprovedRequestPages);
      }
      return visiblePages;
    };

    const pageNumbers = generatePageNumbers();

    return (
      <div className="flex justify-start my-4">
        <button
          onClick={() =>
            setCurrentApprovedRequestPage((prev) => Math.max(prev - 1, 1))
          }
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentApprovedRequestPage === 1
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={currentApprovedRequestPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span key={`ellipsis-${index}`} className="px-2 py-1 mx-1">
              ...
            </span>
          ) : (
            <button
              key={number}
              onClick={() => setCurrentApprovedRequestPage(number)}
              className={`px-2 py-1 mx-1 border rounded ${
                currentApprovedRequestPage === number
                  ? "bg-black text-white"
                  : "bg-white text-black"
              }`}
            >
              {number}
            </button>
          ),
        )}
        <button
          onClick={() =>
            setCurrentApprovedRequestPage((prev) =>
              Math.min(prev + 1, totalApprovedRequestPages),
            )
          }
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentApprovedRequestPage === totalApprovedRequestPages
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={currentApprovedRequestPage === totalApprovedRequestPages}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="py-2 px-2 mb-4 md:-mt-10   md:!py-10">
      {/* Tab Navigation */}
      <div className="flex justify-start  mb-4 ml-2 md:ml-4">
        <button
          className={`px-3 py-2 rounded-l-full text-xs md:min-w-[140px] ${
            activeTab === "approved"
              ? "bg-black text-white"
              : "bg-white text-black border-2 border-black"
          }`}
          onClick={() => setActiveTab("approved")}
        >
          Connections
        </button>
        <button
          className={`px-3 py-2 text-xs md:min-w-[140px] ${
            activeTab === "pending"
              ? "bg-black text-white"
              : "bg-white text-black border-2 border-black"
          }`}
          onClick={() => setActiveTab("pending")}
        >
          Pending
        </button>
        <button
          className={`px-3 py-2 rounded-r-full text-xs md:min-w-[160px] ${
            activeTab === "invitations"
              ? "bg-black text-white"
              : "bg-white text-black border-2 border-l-1 border-black"
          }`}
          onClick={() => setActiveTab("invitations")}
        >
          Invitations Received
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "approved"
        ? renderApprovedTab()
        : activeTab === "pending"
          ? renderPendingTab()
          : renderInvitationsTab()}
    </div>
  );
};

export default MyNetwork;
