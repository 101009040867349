import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="text-center text-lg-start bg-white text-dark">
      {/* Section: Social media */}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        {/* Left */}
        <div className="me-5 d-none d-lg-block">
          <span className="text-lg antialiased font-semibold">
            Get connected with us on social networks:
          </span>
        </div>
        {/* Left */}
        {/* Right */}
        <div className="ml-10">
          <a href="" className="me-4 text-reset">
            <i className="fab fa-facebook-f text-black" />
          </a>
          <a href="" className="me-4 text-reset">
            <i className="fab fa-twitter text-black" />
          </a>
          <a href="mailto:Kreedifywithus@gmail.com" className="me-4 text-reset">
            <i className="fab fa-google text-black" />
          </a>
          <a href="" className="me-4 text-reset">
            <i className="fab fa-instagram text-black" />
          </a>
          <a
            href="https://www.linkedin.com/in/Kreedify-3a181a270/"
            className="me-4 text-reset"
          >
            <i className="fab fa-linkedin text-black" />
          </a>
          <a href="" className="me-4 text-reset">
            <i className="fab fa-github text-black" />
          </a>
        </div>
        {/* Right */}
      </section>
      {/* Section: Social media */}
      {/* Section: Links  */}
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              {/* Content */}
              <img
                className="-mt-7 -ml-9"
                src="/logo.png"
                alt="logo"
                style={{ width: "160px", height: "100px" }}
              />

              <p className="-mt-4">
                Grow Your Business with Kreedify<br></br>
                Your seed fund journey begins right here.
              </p>
            </div>

            <div className="col-md-2 col-lg-3 col-xl-3 mx-auto mb-4 d-flex flex-column">
              <a href="/main/contact" className="aboutUs">
                Contact us
              </a>
              {/*<a href="/main/scout" className="aboutUs">
                Become a kreedify pro
              </a>
              */}
              <a href="/main/newsbrowser" className="aboutUs">
                News
              </a>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4 text-black">
                Contact
              </h6>
              <p>
                <i className="fas fa-home me-3" /> Singapore
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Copyright */}
      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2023 Copyright:
        <a className="text-reset fw-bold" href="/main/home">
          Kreedify
        </a>
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;
