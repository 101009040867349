import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const CallToActionCard = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col items-center justify-center py-10 px-10 md:px-16 rounded-lg shadow-lg mx-4 md:!mx-auto"
      style={{
        background: "linear-gradient(to bottom, #b0ff4b, white)",
        borderRadius: "20px",
        maxWidth: "1000px",
      }}
    >
      <div className="text-center">
        <h2 className="text-3xl font-bold text-black mb-2">
          Join Kreedify Today!
        </h2>
        <p className="text-lg text-black mb-4">
          Start your journey to unlocking connections with investors through our
          trusted network of pros. Build relationships, raise funding, and grow
          your business faster.
        </p>
        <button class="button" onClick={() => navigate("/main/signup")}>
          Get Started Now
          <div class="hoverEffect">
            <div></div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default CallToActionCard;
