import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app_config from "../../config";
import ChatListItemCard from "./ChatListItemCard";
import { CircularProgress } from "@mui/material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ChatList({
  changeChat,
  selectedChat,
  socket,
  contacts,
  setContacts,
}) {
  const [localSelectedChat, setLocalSelectedChat] = useState(null); // State to manage local selected chat
  const [isLoadingChats, setIsLoadingChats] = useState(true);
  const userType = sessionStorage.getItem("investor") ? "investor" : "startup";

  useEffect(() => {
    setIsLoadingChats(true);
    const getUserById = async () => {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${app_config.apiurl}/chat/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log(data, "chatlist data");
      let updatedContacts = data.contacts;

      // Check if the newChat is already in the contacts
      if (
        selectedChat &&
        !updatedContacts.some((contact) => contact._id === selectedChat._id)
      ) {
        updatedContacts = [...updatedContacts, selectedChat];
      }

      setContacts(updatedContacts);
      setIsLoadingChats(false);
    };
    getUserById();
  }, []);

  const changeCurrentChat = async (index, chat) => {
    console.log(index);
    console.log(chat, "chat");
    if (chat.lastMessage && !chat.lastMessage.read) {
      await fetch(
        `${app_config.apiurl}/chat/updatereadstatus/${chat.lastMessage._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ read: true }),
        },
      );
    }

    setLocalSelectedChat(chat);
    changeChat(chat);
    setIsLoadingChats(false);
  };

  if (contacts.length === 0 && isLoadingChats === false) {
    return (
      <div className="text-center text-gray-500  my-4">
        No contacts available.<br></br>
        <Link
          to={
            userType === "investor"
              ? "/investor/startuplist"
              : "/startup/introai"
          }
          className="text-blue-500 underline"
        >
          Create your network
        </Link>
      </div>
    );
  }

  return (
    <>
      <ul className="overflow-auto h-[66vh] md:h-[61vh] scrollbar-custom">
        <h2 className="my-2 mb-2 ml-2 text-gray-900 ">Chats</h2>
        {isLoadingChats && contacts.length === 0 ? (
          <div className="text-center mt-64">
            <CircularProgress color="success" />
          </div>
        ) : !isLoadingChats && contacts.length !== 0 ? (
          contacts.map((contact, index) => (
            <div
              key={contact._id}
              style={{ borderColor: "rgb(229 231 235)" }}
              className={classNames(
                contact._id === (localSelectedChat?._id || selectedChat?._id)
                  ? "bg-gray-200  "
                  : "transition duration-150 ease-in-out cursor-pointer bg-white  hover:bg-gray-300",
                "flex items-center  text-sm",
              )}
              onClick={() => changeCurrentChat(index, contact)}
            >
              <ChatListItemCard user={contact} />
            </div>
          ))
        ) : null}
      </ul>
    </>
  );
}
