import React from "react";
import { MDBInput } from "mdb-react-ui-kit";

const Step0ProfileDetails = ({
  setFieldValue,
  values,
  handleChange,
  errors,
  touched,
  handleFileChange,
}) => (
  <>
    <div className="image-body mt-6 md:mt-0 flex flex-col justify-center items-center">
      <label
        htmlFor="startupimage"
        className="text-lg font-semibold text-black"
      >
        Your profile Image
      </label>
      <br />
      <div className="relative flex items-center justify-center w-20 h-20 rounded-full border-2 border-gray-300 bg-gray-100 overflow-hidden cursor-pointer -mt-2">
        {values.imagePreview ? (
          <img
            src={values.imagePreview}
            alt="Startup Preview"
            className="w-full h-full object-cover object-top rounded-full"
          />
        ) : (
          // Updated Profile Icon placeholder
          <svg
            className="w-16 h-16 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 12c2.67 0 4.833-2.167 4.833-4.833S14.667 2.333 12 2.333 7.167 4.5 7.167 7.167 9.333 12 12 12zm0 2c-3.21 0-9 1.6-9 4.833v2.167H21v-2.167C21 15.6 15.21 14 12 14z" />
          </svg>
        )}
        <input
          type="file"
          name="startupimage"
          className="absolute inset-0 opacity-0 cursor-pointer"
          onChange={(e) => handleFileChange(setFieldValue, e)}
          accept="image/*"
        />
      </div>

      {/* Dynamic text for upload and re-upload */}
      <p className="mt-2 text-sm font-medium text-gray-500">
        {values.imagePreview
          ? "*Click to Re-upload Image"
          : "*Click to Upload Image"}
      </p>

      {errors.startupimage && touched.startupimage ? (
        <div className="text-deny">{errors.startupimage}</div>
      ) : null}
    </div>
    <div className="form-outline mt-8">
      <MDBInput
        label="Your Name"
        id="firstName"
        type="text"
        value={values.firstName}
        onChange={handleChange}
        name="firstName"
      />
      {errors.firstName && touched.firstName ? (
        <div className="text-deny">{errors.firstName}</div>
      ) : null}
    </div>
  </>
);

export default Step0ProfileDetails;
