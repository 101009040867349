import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

export const NavMenuItem = ({ menu, setIsMenuOpen }) => {
  return (
    <div className="py-1" onClick={() => setIsMenuOpen(false)}>
      <NavLink
        to={menu.link}
        className={({ isActive }) =>
          `block pl-4 py-2 mx-2 text-sm text-gray-700 hover:bg-gray-100 ${
            isActive ? "bg-skincolor rounded-md" : ""
          }`
        }
      >
        <i className={`fa ${menu.icon} me-4 py-2`} aria-hidden="true" />
        {menu.label}
      </NavLink>
    </div>
  );
};

export const ActionMenuItem = ({ menu, setIsMenuOpen }) => {
  return (
    <div className="py-1" onClick={() => setIsMenuOpen(false)}>
      <a
        href={menu.link}
        className="block pl-4 mx-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
        onClick={menu.onClick}
      >
        <i className={`fa ${menu.icon} me-4 py-2`} aria-hidden="true" />
        {menu.label}
      </a>
    </div>
  );
};

export const SideBarNavMenuItem = ({ menu, currentPathName, onClick }) => {
  return (
    <Fragment key={menu.label}>
      <NavLink
        to={menu.link}
        className={`navbar-brand py-2 mx-2 ${
          currentPathName === menu.link ? "bg-skincolor rounded-md" : ""
        }`}
      >
        <div className="col ms-4" onClick={onClick}>
          <p className="text-xl md:text-sm">
            <i className={`fa ${menu.icon} me-1 py-2`} aria-hidden="true"></i>
            &nbsp; {menu.label}
          </p>
        </div>
      </NavLink>
    </Fragment>
  );
};

export const SideBarActionMenuItem = ({ menu, currentPathName, onClick }) => {
  console.log("Render action menu:" + menu.label);
  return (
    <Fragment key={menu.label}>
      <a
        href={menu.link}
        onClick={menu.onClick}
        className={`navbar-brand py-2 mx-2 ${
          currentPathName === menu.link ? "bg-skincolor rounded-md" : ""
        }`}
      >
        <div
          className="col ms-4"
          style={{ display: "flex", alignItems: "center" }}
          onClick={onClick}
        >
          <p className="text-xl md:text-base">
            <i className={`fa ${menu.icon} me-1 py-2 `} aria-hidden="true"></i>
            &nbsp; {menu.label}
          </p>
        </div>
      </a>
    </Fragment>
  );
};
