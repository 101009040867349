import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStartupContext } from "../../context/StartupProvider";
import { isAvatarImageAvailable } from "../../util.js";
import AlphabetAvatar from "../AlphabetAvatar";
import app_config from "../../config.js";
import { io } from "socket.io-client";

const drawerWidth = 180;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, isMobileView }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobileView ? 0 : `-${drawerWidth}px`,
    ...(open &&
      !isMobileView && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function StartupDashboardContainer({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(
    () => JSON.parse(sessionStorage.getItem("startup")).unreadNotifications,
  );
  const location = useLocation(); // Get the current location
  const currentUser = JSON.parse(sessionStorage.getItem("startup"));
  const navigate = useNavigate();
  const [socket, setSocket] = useState(
    io(app_config.apiurl, { autoConnect: false }),
  );

  const handleNotificationClick = () => {
    navigate("/startup/notifications"); // Replace with the actual notification page path
  };

  const { logout } = useStartupContext();
  const sideMenu = [
    {
      id: "1",
      label: "Kreedify pros",
      link: "/startup/investorlist",
      icon: "fa-th-list",
    },
    {
      id: "2",
      label: "Intro AI",
      link: "/startup/introai",
      icon: "fa-th-list",
    },
    {
      id: "3",
      label: "My Profile",
      link: `/startup/myprofile/${currentUser._id}`,
      icon: "fa-user-circle",
    },
    { id: "4", label: "Inbox", link: "/startup/inbox", icon: "fa-envelope" },
    {
      id: "5",
      label: "Lean Canvas Model",
      link: "/startup/leancanvasmodel",
      icon: "fa-lightbulb",
    },
    {
      id: "6",
      label: "News",
      link: "/startup/newsbrowser",
      icon: "fa-book-open",
    },
    { id: "7", label: "Logout", onClick: logout, icon: "fa-sign-out-alt" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 840);
      setIsSideBarOpen(!isMobileView);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileView]);

  const handleSideBarOpen = () => {
    setIsSideBarOpen((prev) => !prev);
  };

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  socket.on(`notification-${currentUser._id}`, (data) => {
    if (location.pathname === "/startup/inbox") {
      if (data.notification.type !== "NEW_MESSAGE") {
        setUnreadNotifications((p) => data.unreadCount);
        // Update sessionStorage
        let user = JSON.parse(sessionStorage.getItem("startup"));
        user.unreadNotifications = data.unreadCount;
        sessionStorage.setItem("startup", JSON.stringify(user));
      }
    } else {
      setUnreadNotifications((p) => data.unreadCount);
      // Update sessionStorage
      let user = JSON.parse(sessionStorage.getItem("startup"));
      user.unreadNotifications = data.unreadCount;
      sessionStorage.setItem("startup", JSON.stringify(user));
    }
  });

  socket.on(`marked-as-read-${currentUser._id}`, (data) => {
    setUnreadNotifications(0);
    // Update sessionStorage
    let user = JSON.parse(sessionStorage.getItem("startup"));
    user.unreadNotifications = 0;
    sessionStorage.setItem("startup", JSON.stringify(user));
  });

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={isSideBarOpen}
        style={{
          color: "black",
          backgroundColor: "#fff",
          height: isMobileView ? "70px" : "60px",
          display: "flex",
          justifyContent: "center",
          ...(!isMobileView && {
            zIndex: 9999,
            boxShadow: "none",
          }),
        }}
      >
        <Toolbar
          style={{
            display: "flex",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSideBarOpen}
            edge="start"
            sx={{ mr: 2, ...(!isMobileView && { display: "none" }) }}
          >
            {isSideBarOpen ? null : <MenuIcon />}
          </IconButton>
          <div className="flex justify-between items-center w-full">
            <div className="">
              <img
                src="\logo.png"
                className="ml-[-40px] h-32  w-[17rem] "
                alt="Kreedify logo"
                loading="lazy"
              />
            </div>
            <Box>
              <div className="flex  justify-center items-center">
                <div
                  className="relative cursor-pointer flex-1 mr-5"
                  onClick={handleNotificationClick}
                >
                  {unreadNotifications !== 0 && (
                    <div className="absolute right-0 top-0 text-white bg-black rounded-full w-5 h-5 flex justify-center items-center text-xs ">
                      {unreadNotifications > 9 ? "9+" : unreadNotifications}
                    </div>
                  )}
                  <div id="notificationButton" type="button">
                    <NotificationsOutlinedIcon
                      sx={{
                        fontSize: 35,
                        color: "black",
                      }}
                    />
                  </div>
                </div>
                <button
                  id="dropdownDefaultButton"
                  type="button"
                  className=" w-12 flex-2 rounded-full text-basecolor"
                  data-dropdown-toggle="dropdown"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  {isAvatarImageAvailable(currentUser.startupimage) ? (
                    <img
                      className="w-10 h-10 md:w-12 md:h-12 rounded-full"
                      src={currentUser.startupimage}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={currentUser.firstName.charAt(0)}
                      className="!h-10 !w-10 md:h-12 md:w-12"
                    />
                  )}
                </button>
              </div>

              {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-60 bg-white rounded-md shadow-lg">
                  {sideMenu.map((menu) => (
                    <div className="py-1" key={menu.id}>
                      <a
                        href={menu.link}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={menu.onClick}
                      >
                        <i
                          className={`fa ${menu.icon} me-4 py-2`}
                          aria-hidden="true"
                        ></i>{" "}
                        {menu.label}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: isMobileView ? 285 : drawerWidth, // Use 280px on mobile, drawerWidth otherwise
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isMobileView ? 285 : drawerWidth,
              backgroundColor: "#fff",
              boxSizing: "border-box",
            },
            ...(isMobileView && {
              position: "fixed",
              zIndex: 1300,
            }),
          }}
          variant={isMobileView ? "temporary" : "persistent"}
          anchor="left"
          open={isSideBarOpen}
          onClose={handleSideBarOpen}
        >
          <DrawerHeader className="items-start md:mb-6">
            {isMobileView && (
              <>
                <div className="ml-7 md:ml-0">
                  <img
                    src="\logo.png"
                    className="h-24 md:h-28 w-96 items-start px-3"
                    alt="Kreedify logo"
                    loading="lazy"
                  />
                </div>

                <IconButton onClick={handleSideBarOpen}>
                  <CloseIcon className="-mt-11 !h-7 !w-7" />
                </IconButton>
              </>
            )}
          </DrawerHeader>
          {/* <Divider /> */}

          {sideMenu.map((menu) => (
            <Fragment key={menu.label}>
              {menu.id !== "7" ? (
                <a
                  href={menu.link}
                  className={`navbar-brand py-2 ${location.pathname === menu.link ? "bg-skincolor" : ""}`} // Apply bg-accept if the current route matches the menu link
                >
                  <div
                    className="col ms-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p className="text-xl md:text-sm">
                      <i
                        className={`fa ${menu.icon} me-1 py-2`}
                        aria-hidden="true"
                      ></i>
                      &nbsp; {menu.label}
                    </p>
                  </div>
                </a>
              ) : (
                <div className="block sm:hidden">
                  <a
                    href={menu.link}
                    onClick={menu.onClick}
                    className={`navbar-brand py-2 ${location.pathname === menu.link ? "bg-skincolor" : ""}`}
                  >
                    <div
                      className="col ms-4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="text-xl md:text-base">
                        <i
                          className={`fa ${menu.icon} me-1 py-2`}
                          aria-hidden="true"
                        ></i>
                        &nbsp; {menu.label}
                      </p>
                    </div>
                  </a>
                </div>
              )}
              {/* <Divider /> */}
            </Fragment>
          ))}
        </Drawer>
        <Main open={isSideBarOpen && !isMobileView} isMobileView={isMobileView}>
          <DrawerHeader className="hidden" />
          {children}
        </Main>
      </Box>
    </Box>
  );
}
