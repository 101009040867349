import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import "./Home.css";
import TabNavigation from "./TabNavigation";
import CalltoActionCard from "./cta";
import { FaGlobe, FaRocket, FaLightbulb, FaChartLine } from "react-icons/fa";
import Testimonial from "./Testimonial";
import BuiltForEveryone from "./BuiltForEveryone";
import FeatureSection from "./FeatureSection";

const Home = () => {
  const [showBanner, setShowBanner] = useState(
    !document.cookie.includes("cookie_consent"),
  );

  const [hideImmediately, setHideImmediately] = useState(false);
  const navigate = useNavigate();

  const handleAccept = () => {
    document.cookie =
      "cookie_consent=accepted; path=/; max-age=" + 60 * 60 * 24 * 30;
    setHideImmediately(true);
    setShowBanner(false);
  };

  return (
    <>
      {/* Cookie */}
      <AnimatePresence>
        {showBanner && (
          <motion.div
            className="fixed bottom-0 left-0 right-0 p-4 z-50 flex justify-center"
            initial={{ y: "100%" }} // Start below the view
            animate={{ y: 0 }} // Slide up to the view
            exit={{ y: "100%" }} // Slide down out of view
            transition={{
              delay: hideImmediately ? 0 : 0.6, // No delay if hideImmediately is true
              duration: hideImmediately ? 0.2 : 1, // Shorter duration if hideImmediately is true
              ease: "easeInOut", // Smoothing function for the transition
            }}
          >
            <div className="w-full max-w-xl p-4 bg-cookie text-white rounded-xl">
              {/* Banner Content */}
              <div className="flex flex-row">
                <div className="flex md:text-left">
                  <p className="text-sm md:text-xl">
                    We use cookies to make our site user friendly.{" "}
                    <br className="hidden md:block"></br>Read our{" "}
                    <a href="#" className="text-accept">
                      Cookie Policy
                    </a>
                    .
                  </p>
                </div>
                <div className="flex mt-2 md:mt-0 md:ml-4 ml-2">
                  <button
                    onClick={handleAccept}
                    className="bg-accept text-white md:px-4 md:py-2 px-3 py-2  rounded font-medium text-sm tracking-widest"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Cookie */}

      {/* <Header /> */}
      <Header />
      <AnimatePresence>
        <motion.div
          className="flex flex-row items-center justify-center p-3 md:p-10 bg-white md:h-[75vh]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="flex flex-col md:flex-row justify-center"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              className="md:mt-28 md:ml-28 ml-5"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-4xl md:text-5xl font-bold py-1 text-black leading-[2.5rem] md:leading-[3.5rem] md:w-[65%]">
                From Code to Connections:<br className="block"></br>
                The AI Matchmaking Platform for Techies
              </h1>

              <p className="py-2 text-black text-xl md:ml-0 mr-4 mb-3 md:mb-4 md:w-[60%]">
                Seamlessly connect with like-minded tech professionals, mentors,
                and collaborators to unlock innovation and opportunities.
              </p>
              <button class="button" onClick={() => navigate("/main/signup")}>
                Free Signup
                <div class="hoverEffect">
                  <div></div>
                </div>
              </button>
            </motion.div>

            <motion.div
              className="flex md:-ml-96 -ml-4 mt-4 md:-mt-2 w-[110%] md:w-[35%]"
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img
                className="hero-image"
                style={{ width: "90%" }}
                src="\home.png"
                alt="Hero Section"
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>

      <TabNavigation />

      <BuiltForEveryone />

      <FeatureSection />

      <section className="mt-8 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-16">
            {/* Content Side */}
            <div className="lg:w-1/2 space-y-8">
              <div className="space-y-4">
                <h2 className="text-4xl lg:text-5xl font-bold text-black">
                  Connecting Global
                  <br className="hidden lg:block" /> Tech Talent
                </h2>
                <div className="w-20 h-1 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-full"></div>
              </div>
              <p className="text-lg text-gray-700 leading-relaxed">
                We are making it easier to find and connect with the best tech
                professionals of the world. Tech communities are very powerful
                that drive innovation at every level.
              </p>
              <div className="flex gap-4">
                <button class="button" onClick={() => navigate("/main/signup")}>
                  Get Started
                  <div class="hoverEffect">
                    <div></div>
                  </div>
                </button>
              </div>
            </div>

            {/* Visual Side */}
            <div className="lg:w-1/2">
              <div className="relative group">
                {/* Animated Background Elements */}
                <div
                  className="absolute -inset-1 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-2xl 
                  blur-xl opacity-75 group-hover:opacity-100 transition duration-1000"
                ></div>
                <div
                  className="absolute -inset-1 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-2xl 
                  blur-sm opacity-75 animate-pulse"
                ></div>

                {/* Main Content Card */}
                <div
                  className="relative bg-white rounded-2xl p-8 shadow-xl backdrop-blur-sm 
                  border border-gray-100/20"
                >
                  <div className="grid grid-cols-2 gap-4">
                    {/* Showcase Content */}
                    {[
                      {
                        icon: <FaGlobe className="text-[#b0ff4b] text-4xl" />,
                        title: "Global Network",
                        description:
                          "Connect with professionals from over 150 countries and grow your network.",
                      },
                      {
                        icon: <FaRocket className="text-[#b0ff4b] text-4xl" />,
                        title: "Startup Support",
                        description:
                          "Access resources and mentorship for startups to thrive in the tech ecosystem.",
                      },
                      {
                        icon: (
                          <FaLightbulb className="text-[#b0ff4b] text-4xl" />
                        ),
                        title: "Innovative Ideas",
                        description:
                          "Collaborate on groundbreaking projects with some of the brightest minds in tech.",
                      },
                      {
                        icon: (
                          <FaChartLine className="text-[#b0ff4b] text-4xl" />
                        ),
                        title: "Career Growth",
                        description:
                          "Find opportunities to upskill and advance your career in the tech industry.",
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col justify-center items-center md:justify-start p-4 rounded-xl bg-gray-50 hover:bg-gray-100 transition-all duration-300 transform hover:-translate-y-1"
                      >
                        <div>{item.icon}</div>
                        <div className="mt-2 font-bold text-base md:text-lg text-gray-800 text-center md:text-start">
                          {item.title}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*How it works section*/}
      {/*
      <div
        className="flex flex-col items-center justify-center p-3 md:-mb-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="text-center">
          <p className="py-2 text-black font-semibold text-4xl">How it works</p>
          <p className="py-2 text-black text-xl">
            Kreedify allows founders to connect with kreedify pros,{" "}
            <br className="hidden sm:block" />
            who have great access to investors in their network.
            <br className="hidden sm:block" />
            Get 1:1 intros with great investors through their network!
          </p>
        </div>
        <div className="flex justify-center md:-mt-2 w-[110%] md:w-[35%]">
          <img src="/kreedifypro.png" alt="" className=" h-48 sm:h-auto" />
        </div>
      </div>
      */}

      {/*Raise funding more effectively*/}
      {/*
      <div
        className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:-ml-36"
        style={{ backgroundColor: "white" }}
      >
        <div className="col-md-3 ml-16 md:ml-0 mb-3">
          <img
            src="/Kreedify_pro_list_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:-ml-20 md:mt-0 text-center md:!text-left">
          <p className="py-2 text-black font-semibold text-4xl ml-4 md:ml-0">
            Raise funding more effectively
          </p>

          <p className="py-2 -ml-12 text-black text-xl ml-4 md:ml-0">
            ‘Raising capital can be so difficult without the network.
            <br className="hidden sm:block"></br>
            Not anymore!<br className="hidden sm:block"></br>
            We make it easier for founders to grow their network &{" "}
            <br className="hidden sm:block"></br>
            make fundraising more easy going’
            <br className="hidden sm:block"></br>
          </p>
        </div>
      </div>
      */}

      {/*Help startup's raise funding*/}
      {/*
      <div className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:ml-0">
        <div className="col-md-3 ml-16 md:ml-0 mb-3 md:hidden">
          <img
            src="/startuplist_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:ml-0 md:mt-0 text-center md:!text-left">
          <h2 className="font-semibold text-black text-4xl py-3">
            Help startup's raise funding
          </h2>
          <p className="py-2 text-black text-xl">
            ‘As working professionals, you may have access to{" "}
            <br className="hidden sm:block"></br>
            investors in your network- Angle, VC or any type.{" "}
            <br className="hidden sm:block"></br>
            Or, you are a VC scout hunting for startups!{" "}
            <br className="hidden sm:block"></br>
            Help founders to access that network you have &{" "}
            <br className="hidden sm:block"></br>
            be their funding assist’<br></br>
          </p>
          <Link
            className="btn normal-case font-bold mt-2 md:mt-8 text-sm"
            style={{ backgroundColor: "#b0ff4b", color: "black" }}
            to="/main/scout"
            onClick={scrollToTop}
          >
            Register as Scout
          </Link>
        </div>
        <div className="col-md-3 ml-36 mb-3 hidden md:block">
          <img
            src="/startuplist_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[20rem] md:h-[30rem] w-[13rem] md:w-[65%]"
          />
        </div>
      </div>
      */}

      {/*Leverage your network*/}
      {/*
      <div className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:-ml-20">
        <div className="col-md-3 ml-16 md:ml-0 mb-3">
          <img
            src="/chatting_mobileMockup.png"
            alt="chatting mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:-ml-10 md:mt-0 text-center md:!text-left">
          <h2 className="font-semibold text-black text-4xl py-3">
            Leverage your network
          </h2>
          <p className="py-2 text-black text-xl">
            ‘Our aim is to amplify using network effects.{" "}
            <br className="hidden sm:block"></br>
            Anyone who has access to investors can join us as a scout to{" "}
            <br className="hidden sm:block"></br>
            help founders! We simply increase the chances for startups to{" "}
            <br className="hidden sm:block"></br>
            raise funds’
          </p>
          <Link
            className="btn normal-case font-bold mt-2 md:mt-8 text-sm"
            style={{ backgroundColor: "#b0ff4b", color: "black" }}
            to="/main/signup"
            onClick={scrollToTop}
          >
            Free Signup
          </Link>
        </div>
      </div>
      */}

      <div className="row justify-content-center mx-auto w-full h-fit mt-6">
        <div
          className="col-md-3 p-4 mx-3 my-4 justify-content-center align-items-center text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ backgroundColor: "white", width: "300px" }}
        >
          <div className="row ">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3133/3133430.png"
                alt="icon"
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>More easier and better connections</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-3 p-4  mx-3 my-4 justify-content-center align-items-center bg-white text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ width: "300px" }}
        >
          <div className="row">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/9576/9576835.png"
                alt="icon1"
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>find your cofounder in any background</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-3  p-4  mx-3 my-4  justify-content-center align-items-center text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ backgroundColor: "white", width: "300px" }}
        >
          <div className="row">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/7656/7656523.png"
                alt="icon2"
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>In-built chat when you connect with profiles</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-3 p-4  mx-3 my-4 bg-white justify-content-center align-items-center text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ width: "300px" }}
        >
          <div className="row">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/1651/1651707.png"
                alt="icon3"
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>Auto call scheduling (coming soon)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Testimonial />
      </div>
      <div className="mt-6 mb-6">
        <CalltoActionCard />
      </div>
    </>
  );
};

export default Home;
