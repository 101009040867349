import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import app_config from "../../config";
import {
  industryFocusOptionLookup,
  networkTypeLookup,
  scoutSupportedStartupStagesLookup,
} from "../../Constants";
import AlphabetAvatar from "../AlphabetAvatar";
import { findCountryLabel } from "../commons/countries";
import ConnectionRequests from "./ConnectionRequests";
import MyNetwork from "./MyNetwork";
import Register from "../investor/Register";

export default function ProfileDetail() {
  const { id } = useParams();
  const [scoutData, setScoutData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [connectionRequests, setConnectionRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [activeTab, setActiveTab] = useState("profile");

  const token = sessionStorage.getItem("token");

  const fetchProfileById = async () => {
    setLoading(true);
    const res = await fetch(`${app_config.apiurl}/users/getbyid/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    setScoutData(data.result);
    setLoading(false);
  };

  const fetchConnectionRequests = async () => {
    const token = sessionStorage.getItem("token");
    const res = await fetch(
      `${app_config.apiurl}/connection-requests/recieved`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    const pendingRequests = data.result.filter(
      (request) => request.sender !== null && request.status === "pending",
    );
    const approvedRequests = data.result.filter(
      (request) => request.sender !== null && request.status === "approved",
    );
    setConnectionRequests(pendingRequests);
    setApprovedRequests(approvedRequests);
  };

  const handleConnectionRequest = async (requestId, approve) => {
    const res = await fetch(
      `${app_config.apiurl}/connection-requests/${requestId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ approve }),
      },
    );
    if (res.ok) {
      fetchConnectionRequests();
    }
  };

  useEffect(() => {
    fetchProfileById();
    fetchConnectionRequests();
  }, []);

  const isAvatarImageAvailable = (imageUrl) => {
    console.log("imageUrl:" + imageUrl);
    return (
      imageUrl !== undefined &&
      imageUrl !== null &&
      imageUrl !== "undefined" &&
      imageUrl.trim().length > 0
    );
  };

  const displayProfile = () => {
    return (
      <>
        <div>
          <div className=" h-[15rem] md:h-[12rem] flex flex-col">
            <div className="border-b border-black pb-2 flex justify-center md:justify-start items-center space-x-4 mt-24 md:mt-12 ml-2 md:ml-12">
              <button
                className={`px-4 py-2 font-medium ${
                  activeTab === "profile"
                    ? "bg-black text-white rounded-lg"
                    : "bg-transparent text-black"
                }`}
                onClick={() => setActiveTab("profile")}
              >
                Profile
              </button>
              <button
                className={`relative px-6 pr-10 py-2 font-medium ${
                  activeTab === "myNetwork"
                    ? "bg-black text-white rounded-lg"
                    : "bg-transparent text-black"
                }`}
                onClick={() => setActiveTab("myNetwork")}
              >
                My Network
                <span className="absolute top-2 right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-black bg-white rounded-full">
                  {approvedRequests.length}
                </span>
              </button>
              <button
                className={`relative px-6 pr-10 py-2 font-medium ${
                  activeTab === "connectionRequests"
                    ? "bg-black text-white rounded-lg"
                    : "bg-transparent text-black"
                }`}
                onClick={() => setActiveTab("connectionRequests")}
              >
                Startup Requests
                <span className="absolute top-2 right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-black bg-white rounded-full">
                  {connectionRequests.length}
                </span>
              </button>
              <button
                className={`relative px-6 py-2 font-medium ${
                  activeTab === "Register"
                    ? "bg-black text-white rounded-lg"
                    : "bg-transparent text-black"
                }`}
                onClick={() => setActiveTab("Register")}
              >
                Edit Profile
              </button>
            </div>
          </div>
          <div className="sm:pl-7 sm:pr-20 mt-[-4rem] md:-mt-8 max-w-6xl flex flex-col sm:flex-row">
            {activeTab === "profile" ? (
              <>
                <div className="col-md-4 mb-4">
                  <div className="flex flex-col gap-2 items-center py-6 sm:p-6 bg-white mx-4 rounded-xl shadow-xl">
                    <div>
                      {isAvatarImageAvailable(scoutData.investoravatar) ? (
                        <img
                          className="w-32 h-32 rounded-full"
                          src={scoutData.investoravatar}
                          alt="avatar"
                        />
                      ) : (
                        <AlphabetAvatar
                          alphabet={scoutData.firstName.charAt(0)}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2 items-center text-center">
                      <h2 className="text-2xl pt-3 font-medium">
                        {scoutData.firstName || scoutData.name}{" "}
                        {scoutData.lastName || ""}
                      </h2>
                      {scoutData.brief && (
                        <p className="text-center">
                          <i className="fas fa-quote-left"></i>{" "}
                          {scoutData.brief}
                        </p>
                      )}
                      <button
                        className="flex items-center gap-2 px-4 py-2 mt-2 bg-skincolor text-black font-medium rounded-lg"
                        onClick={() => setActiveTab("Register")}
                      >
                        <i className="fas fa-edit"></i> Edit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 px-6 bg-white shadow-xl rounded-xl mb-4 mx-4">
                  <div>
                    <div className="py-2 border-b border-[#d3cdcd]">
                      <h2 className="font-medium text-[19px]">
                        Basic Information
                      </h2>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-3 ml-2">
                      <div className="sm:w-[55%]">
                        <div className="bg-white rounded-xl p-2">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">Email</p>
                            <p
                              className="font-medium break-words"
                              style={{ wordBreak: "break-word" }}
                            >
                              {scoutData.email}
                            </p>
                          </div>
                        </div>

                        <div className="bg-white rounded-xl p-2 mt-3 sm:mt-0">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">Current Position</p>
                            <p className="font-medium">
                              {scoutData?.currentPosition || "Not Available"}
                            </p>
                          </div>
                        </div>

                        <div className="bg-white rounded-xl p-2 mt-3">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">
                              Experience helping startups raise funding
                            </p>
                            <p className="font-medium">
                              {scoutData?.experience || "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="sm:w-[45%]">
                        <div className="bg-white rounded-xl p-2">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">LinkedIn URL</p>
                            <a
                              href={scoutData?.linkedInUrl || "#"}
                              target="_blank"
                              className="font-medium overflow-hidden whitespace-nowrap"
                              style={{
                                maxWidth: "300px",
                                textOverflow: "ellipsis",
                                display: "block",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {scoutData?.linkedInUrl || "Not Available"}
                            </a>
                          </div>
                        </div>

                        <div className="bg-white rounded-xl p-2 mt-3 sm:mt-0">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">Expertise</p>
                            <p
                              className="font-medium break-words"
                              style={{ wordBreak: "break-word" }}
                            >
                              {scoutData?.expertise || "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-4 sm:!pt-0">
                    <div className="py-2 border-b border-[#d3cdcd]">
                      <h2 className="font-medium text-[19px]">
                        Additional Information
                      </h2>
                    </div>
                    <div className="flex flex-col sm:flex-row">
                      <div className="sm:w-[55%]">
                        <div className="bg-white rounded-xl p-3">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">
                              Your industry focus:
                            </p>
                            <p className="font-medium">
                              {industryFocusOptionLookup[
                                scoutData?.industryFocus
                              ] || "Not Available"}
                            </p>
                          </div>
                        </div>

                        <div className="bg-white rounded-xl p-3 mt-3">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">
                              Stage of startup’s you help:
                            </p>
                            <p className="font-medium">
                              {scoutSupportedStartupStagesLookup[
                                scoutData?.stage
                              ] || "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:w-[45%]">
                        <div className="bg-white rounded-xl p-3">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">
                              Type of Investor Network:
                            </p>
                            <p className="font-medium">
                              {networkTypeLookup[scoutData?.networkType] ||
                                "Not Available"}
                            </p>
                          </div>
                        </div>

                        <div className="bg-white rounded-xl p-3 mt-3 sm:mt-0">
                          <div className="flex flex-col gap-2">
                            <p className="text-[#a3a0a0]">Location:</p>
                            <p className="font-medium">
                              {findCountryLabel(scoutData?.location) ||
                                "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="bg-white rounded-xl p-3">
                        <div className="flex flex-col gap-2">
                          <p className="text-[#a3a0a0]">More bio:</p>
                          <p className="font-medium">
                            {scoutData?.bio || "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : activeTab === "connectionRequests" ? (
              <ConnectionRequests
                connectionRequests={connectionRequests}
                handleConnectionRequest={handleConnectionRequest}
              />
            ) : activeTab === "myNetwork" ? (
              <MyNetwork approvedRequests={approvedRequests} />
            ) : activeTab === "Register" ? (
              <Register />
            ) : null}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div className="text-center mt-64">
          <CircularProgress color="success" />
        </div>
      ) : (
        scoutData && displayProfile()
      )}
    </>
  );
}
