import { Formik } from "formik";
import { useState } from "react";
import Swal from "sweetalert2";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import * as Yup from "yup";
import app_config from "../../config";
import {
  industryFocusOption,
  networkTypes,
  experienceHelpingStartups,
  scoutSupportedStartupStages,
} from "../../Constants";
import { countries } from "../commons/countries";

const RegisterSchema1 = Yup.object().shape({
  firstName: Yup.string()
    .min(2)
    .required("First Name is required")
    .max(50, "First Name must be atmost 10 characters"),
  lastName: Yup.string()
    .min(2)
    .required("Last Name is required")
    .max(50, "Last Name must be atmost 10 characters"),
  currentPosition: Yup.string()
    .min(2)
    .max(50, "Current position limit exceeded")
    .required("Current position is required"),
  expertise: Yup.string()
    .min(2)
    .max(50, "Expertise limit exceeded")
    .required("Expertise is required"),
  industryFocus: Yup.string().required("Industry focus, is required"),
  experience: Yup.string().required("Experience Helping Startup, is required"),
  stage: Yup.string().required("Stage of startups is required"),
  networkType: Yup.string()
    .max(50, "Type of investor network limit exceeded")
    .required("Type of investor network is required"),
});

const RegisterSchema2 = Yup.object().shape({
  linkedInUrl: Yup.string().min(2).required("LinkedIn URL is required"),
  bio: Yup.string()
    .min(2)
    .required("Your bio is required")
    .test("max-words", "Bio must be at most 150 words", (value) =>
      value
        ? value.split(/\s+/).filter((word) => word.length > 0).length <= 150
        : true,
    ),
  email: Yup.string()
    .min(2)
    .email("Invalid email")
    .required("Email is required"),
  location: Yup.string()
    .min(2)
    .max(14, "Location limit exceeded")
    .required("Location is required"),
});

const Register = () => {
  const [selimage, setSelimage] = useState(null);
  const url = app_config.apiurl;
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(sessionStorage.getItem("investor")),
  );

  const token = sessionStorage.getItem("token");

  const getProfileImage = (e) => {
    const file = e.target.files[0];
    setSelimage(file);
  };

  const uploadImage = async (file) => {
    const fd = new FormData();
    fd.append("myfile", file);
    try {
      const res = await fetch(url + "/util/uploadfile", {
        method: "POST",
        body: fd,
      });
      // if (response.status === 200) {
      //   return file.name;
      // }
      let response = await res.json();
      return res.status === 200 ? response.url : null;
    } catch (error) {
      return "";
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(true);
    if (selimage != null) {
      let imageName = await uploadImage(selimage);
      values.investoravatar = imageName;
    }
    const res = await fetch(
      `${app_config.apiurl}/users/update/${currentUser._id}`,
      {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    setSubmitting(false);
    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your data saved successfully",
      });
      const response = await res.json();
      sessionStorage.setItem("investor", JSON.stringify(response.result));
      setCurrentUser(response.result);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",

        text: "Please try again later",
      });
    }
  };

  return (
    <>
      <div
        className="md:ml-36 md:mx-12 mx-20 md:-mt-4 mt-10 mb-4"
        style={{ height: "fit-content", width: "fit-content" }}
      >
        <h1 className="md:-ml-28 text-black md:text-4xl text-3xl font-semibold sm:mt-0">
          Edit Profile
        </h1>
        <ul
          className="nav nav-tabs nav-justified mt-3 md:space-x-24 mb-4 md:-ml-28"
          id="ex1"
          role="tablist"
          style={{ width: "fit-content" }}
        >
          <li className="nav-item" role="presentation">
            <a
              className="nav-link active"
              id="ex3-tab-1"
              data-mdb-toggle="tab"
              href="#ex3-tabs-1"
              role="tab"
              aria-controls="ex3-tabs-1"
              aria-selected="true"
            >
              Basic Info
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link"
              id="ex3-tab-3"
              data-mdb-toggle="tab"
              href="#ex3-tabs-3"
              role="tab"
              aria-controls="ex3-tabs-3"
              aria-selected="false"
            >
              Personal details
            </a>
          </li>
        </ul>

        <div className="tab-content md:-ml-28" id="ex2-content">
          <div
            className="tab-pane fade show active"
            id="ex3-tabs-1"
            role="tabpanel"
            aria-labelledby="ex3-tab-1"
          >
            <div style={{ height: "fit-content" }}>
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-About Startup"
                  role="tabpanel"
                  aria-labelledby="v-pills-About Startup-tab"
                >
                  <Formik
                    initialValues={{
                      investoravatar: currentUser.investoravatar || "",
                      firstName:
                        currentUser.firstName || currentUser.name || "",
                      lastName: currentUser.lastName || "",
                      currentPosition: currentUser.currentPosition || "",
                      experience: currentUser.experience || "",
                      typeOfInvestor: currentUser.typeOfInvestor || "",
                      expertise: currentUser.expertise || "",
                      industryFocus: currentUser.industryFocus || "",
                      stage: currentUser.stage || "",
                      networkType: currentUser.networkType || "",
                    }}
                    validationSchema={RegisterSchema1}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      errors,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex flex-column">
                          <div className="form-outline mb-2">
                            <div className="file-upload-wrapper">
                              <div className="image-body ml-2">
                                <label
                                  htmlFor="investoravatar"
                                  className="text-base text-black mb-2"
                                >
                                  Investor Avatar
                                </label>
                                <br />
                                <div className="relative flex items-center justify-center w-20 h-20 rounded-full border-2 border-gray-300 bg-gray-100 overflow-hidden cursor-pointer">
                                  {selimage || values.investoravatar ? (
                                    <img
                                      src={
                                        selimage
                                          ? URL.createObjectURL(selimage)
                                          : values.investoravatar
                                      }
                                      alt="Avatar Preview"
                                      className="w-full h-full object-cover object-top rounded-full"
                                    />
                                  ) : (
                                    <img
                                      src="/AvatarPlaceholder.svg"
                                      alt="Avatar Placeholder"
                                      className="w-[85%] h-[85%]"
                                    />
                                  )}
                                  <input
                                    type="file"
                                    name="investoravatar"
                                    className="absolute inset-0 opacity-0 cursor-pointer"
                                    onChange={(e) => getProfileImage(e)}
                                    accept="image/*"
                                  />
                                </div>
                                <p className="mt-2 mb-2 text-sm font-medium text-gray-500">
                                  {selimage || values.investoravatar
                                    ? "*Click to Re-upload Avatar"
                                    : "*Click to Upload Avatar"}
                                </p>

                                {errors.investoravatar &&
                                touched.investoravatar ? (
                                  <div className="text-deny">
                                    {errors.investoravatar}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="form-outline mb-4 md:w-[120%]">
                            <MDBInput
                              label="Investor First Name"
                              type="text"
                              value={values.firstName}
                              onChange={handleChange}
                              name="firstName"
                            />
                            {errors.firstName && touched.firstName ? (
                              <div className="text-deny">
                                {errors.firstName}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-outline mb-4 md:w-[120%]">
                            <MDBInput
                              label="Investor Last Name"
                              type="text"
                              value={values.lastName}
                              onChange={handleChange}
                              name="lastName"
                            />
                            {errors.lastName && touched.lastName ? (
                              <div className="text-deny">{errors.lastName}</div>
                            ) : null}
                          </div>

                          <div className="form-outline mb-4 md:w-[120%]">
                            <MDBInput
                              label="Current position"
                              type="text"
                              value={values.currentPosition}
                              onChange={handleChange}
                              name="currentPosition"
                            />
                            {errors.currentPosition &&
                            touched.currentPosition ? (
                              <div className="text-deny">
                                {errors.currentPosition}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-outline mb-4 md:w-[120%]">
                            <select
                              id="experience"
                              onChange={handleChange}
                              value={values.experience}
                              className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            >
                              <option value="">
                                Experience Helping Startups
                              </option>
                              {experienceHelpingStartups.map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.experience && touched.experience ? (
                              <div className="text-deny">
                                {errors.experience}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-outline mb-4 md:w-[120%]">
                            <MDBInput
                              label="Your Expertise"
                              type="text"
                              value={values.expertise}
                              onChange={handleChange}
                              name="expertise"
                            />
                            {errors.expertise && touched.expertise ? (
                              <div className="text-deny">
                                {errors.expertise}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-outline mb-4 md:w-[120%]">
                            <select
                              id="industryFocus"
                              onChange={handleChange}
                              value={values.industryFocus}
                              className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            >
                              <option value="">Your Industry focus</option>
                              {industryFocusOption.map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.industryFocus && touched.industryFocus ? (
                              <div className="text-deny">
                                {errors.industryFocus}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-outline mb-4 md:w-[120%]">
                            <select
                              id="stage"
                              onChange={handleChange}
                              value={values.stage}
                              className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            >
                              <option value="">
                                Stage of startup’s you help
                              </option>
                              {scoutSupportedStartupStages.map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.stage && touched.stage ? (
                              <div className="text-deny">{errors.stage}</div>
                            ) : null}
                          </div>
                          <div className="form-outline mb-4 md:w-[120%]">
                            <select
                              id="networkType"
                              onChange={handleChange}
                              value={values.networkType}
                              className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                              <option value="">Type of Investor Network</option>
                              {networkTypes.map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.networkType && touched.networkType ? (
                              <div className="text-deny">
                                {errors.networkType}
                              </div>
                            ) : null}
                          </div>
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="btn btn-block mb-1 md:w-[120%]"
                            style={{
                              backgroundColor: "#b0ff4b",
                              color: "black",
                            }}
                          >
                            {isSubmitting ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="ex3-tabs-3"
            role="tabpanel"
            aria-labelledby="ex3-tab-3"
          >
            <div style={{ height: "500px" }}>
              <Formik
                initialValues={{
                  linkedInUrl: currentUser.linkedInUrl || "",
                  bio: currentUser.bio || "",
                  location: currentUser.location || "",
                  email: currentUser.email || "",
                }}
                validationSchema={RegisterSchema2}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-outline mb-4 md:w-[120%]">
                      <MDBInput
                        label="LinkedIn URL"
                        type="url"
                        value={values.linkedInUrl}
                        onChange={handleChange}
                        name="linkedInUrl"
                      />
                      {errors.linkedInUrl && touched.linkedInUrl ? (
                        <div className="text-deny">{errors.linkedInUrl}</div>
                      ) : null}
                    </div>
                    <div className="form-outline mb-4 md:w-[120%]">
                      <MDBInput
                        label="Email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        name="email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-deny">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-outline mb-4 md:w-[120%]">
                      <MDBTextArea
                        label="Your Bio"
                        type="text"
                        value={values.bio}
                        onChange={(e) => {
                          const input = e.target.value;
                          const wordCount = input
                            .split(/\s+/)
                            .filter((word) => word.length > 0).length;

                          if (wordCount <= 150) {
                            handleChange(e);
                          }
                        }}
                        name="bio"
                      />
                      {errors.bio && touched.bio ? (
                        <div className="text-deny">{errors.bio}</div>
                      ) : null}
                      <div>
                        Word Count:{" "}
                        {values.bio
                          ? values.bio
                              .split(/\s+/)
                              .filter((word) => word.length > 0).length
                          : 0}
                        /150
                      </div>
                    </div>
                    <div className="form-outline mb-4 md:w-[120%]">
                      <select
                        id="location"
                        onChange={handleChange}
                        value={values.location}
                        className="bg-white border border-black-900 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      >
                        <option value="">Location</option>
                        {countries.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.location && touched.location ? (
                        <div className="text-deny">{errors.location}</div>
                      ) : null}
                    </div>

                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-block mb-1 md:w-[120%]"
                      style={{ backgroundColor: "#b0ff4b", color: "black" }}
                    >
                      {isSubmitting ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
