import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { isAvatarImageAvailable } from "../../util";
import AlphabetAvatar from "../AlphabetAvatar";
import { ActionMenuItem, NavMenuItem } from "./MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import app_config from "../../config";

const DashboardHeader = ({
  menuItems,
  isSideBarOpen,
  handleSideBarOpen,
  isMobileView,
  profileImageUrl,
  userName,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [unreadNotifications, setUnreadNotifications] = useState(
    () => JSON.parse(sessionStorage.getItem("startup")).unreadNotifications,
  );

  const currentUser = JSON.parse(sessionStorage.getItem("startup"));

  const [socket, setSocket] = useState(
    io(app_config.apiurl, { autoConnect: false }),
  );
  const location = useLocation();

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  socket.on(`notification-${currentUser._id}`, (data) => {
    if (
      window.location.pathname === "/startup/inbox" &&
      data.notification.type === "NEW_MESSAGE"
    )
      return;
    setUnreadNotifications((p) => data.unreadCount);
    // Update sessionStorage
    let user = JSON.parse(sessionStorage.getItem("startup"));
    user.unreadNotifications = data.unreadCount;
    sessionStorage.setItem("startup", JSON.stringify(user));
  });

  socket.on(`marked-as-read-${currentUser._id}`, (data) => {
    setUnreadNotifications(0);
    // Update sessionStorage
    let user = JSON.parse(sessionStorage.getItem("startup"));
    user.unreadNotifications = 0;
    sessionStorage.setItem("startup", JSON.stringify(user));
  });

  return (
    <AppBar
      position="fixed"
      open={isSideBarOpen}
      style={{
        color: "black",
        backgroundColor: "#fff",
        height: isMobileView ? "70px" : "60px",
        display: "flex",
        justifyContent: "center",
        ...(!isMobileView && {
          zIndex: 9999,
          boxShadow: "none",
        }),
      }}
    >
      <Toolbar
        style={{
          display: "flex",
        }}
      >
        {!isSideBarOpen && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSideBarOpen}
            edge="start"
            sx={{ mr: 2, ...(!isMobileView && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <div className="flex justify-between items-center w-full">
          <div className="">
            <img
              src="\logo.png"
              className="ml-[-40px] h-32  w-[17rem] "
              alt="Kreedify logo"
              loading="lazy"
            />
          </div>
          <Box>
            <div className="flex  justify-center items-center">
              <div
                className="relative cursor-pointer flex-1 mr-5"
                onClick={() => {
                  navigate("/startup/notifications");
                }}
              >
                {unreadNotifications !== 0 && (
                  <div className="absolute right-0 top-0 text-white bg-black rounded-full w-5 h-5 flex justify-center items-center text-xs ">
                    {unreadNotifications > 9 ? "9+" : unreadNotifications}
                  </div>
                )}
                <div id="notificationButton" type="button">
                  <NotificationsOutlinedIcon
                    sx={{
                      fontSize: 35,
                      color: "black",
                    }}
                  />
                </div>
              </div>
              <button
                id="dropdownDefaultButton"
                type="button"
                className=" w-12 flex-2 rounded-full text-basecolor"
                data-dropdown-toggle="dropdown"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isAvatarImageAvailable(profileImageUrl) ? (
                  <img
                    className="w-10 h-10 md:w-12 md:h-12 rounded-full"
                    src={profileImageUrl}
                    alt="avatar"
                  />
                ) : (
                  <AlphabetAvatar
                    alphabet={userName.charAt(0)}
                    className="!h-10 !w-10 md:h-12 md:w-12"
                  />
                )}
              </button>
            </div>

            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-52 bg-white rounded-md shadow-lg">
                {menuItems.map((menu) =>
                  menu.link ? (
                    <NavMenuItem menu={menu} setIsMenuOpen={setIsMenuOpen} />
                  ) : (
                    <ActionMenuItem menu={menu} setIsMenuOpen={setIsMenuOpen} />
                  ),
                )}
              </div>
            )}
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
