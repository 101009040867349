export const startupSideMenus = (currentUser, logout) => {
  return [
    {
      id: "1",
      label: "Kreedify pros",
      link: "/startup/investorlist",
      icon: "fa-th-list",
    },
    {
      id: "2",
      label: "Intro AI",
      link: "/startup/introai",
      icon: "fa-th-list",
    },
    {
      id: "3",
      label: "My Profile",
      link: `/startup/myprofile/${currentUser._id}`,
      icon: "fa-user-circle",
    },
    { id: "4", label: "Inbox", link: "/startup/inbox", icon: "fa-envelope" },
    {
      id: "5",
      label: "Lean Canvas Model",
      link: "/startup/leancanvasmodel",
      icon: "fa-lightbulb",
    },
    {
      id: "6",
      label: "Tech News",
      link: "/startup/newsbrowser",
      icon: "fa-book-open",
    },
    { id: "7", label: "Logout", onClick: logout, icon: "fa-sign-out-alt" },
  ];
};

export const investorSideMenus = (currentUser, logout) => {
  return [
    {
      id: "1",
      label: "Startups",
      link: "/investor/startuplist",
      icon: "fa-th-list",
    },
    {
      id: "2",
      label: "My Profile",
      link: `/investor/myprofile/${currentUser._id}`,
      icon: "fa-user-circle",
    },
    { id: "3", label: "Inbox", link: "/investor/inbox", icon: "fa-envelope" },
    {
      id: "4",
      label: "Tech News",
      link: "/investor/newsbrowser",
      icon: "fa-book-open",
    },
    {
      id: "5",
      label: "Faq",
      link: "/investor/faq",
      icon: "fa-question-circle",
    },
    { id: "6", label: "Logout", onClick: logout, icon: "fa-sign-out-alt" },
  ];
};
