import React from "react";
import "./Home.css";

const FeatureSection = () => {
  return (
    <div>
      {/* first section*/}
      <section className="pt-12 pb-16 mb-6 relative overflow-hidden">
        {/* Animated Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-20 left-10 w-96 h-96 bg-gradient-to-r from-[#b0ff4b]/20 to-[#8acc3d]/20 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute bottom-20 right-10 w-96 h-96 bg-gradient-to-r from-[#8acc3d]/20 to-[#b0ff4b]/20 rounded-full blur-3xl animate-pulse delay-1000"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="grid md:grid-cols-2 items-center">
            {/* Content Side */}
            <div className="relative z-10 space-y-10">
              {/* Icon Container with 3D effect */}
              <div className="relative group">
                <div className="w-24 h-24 bg-white backdrop-blur-xl rounded-3xl flex items-center justify-center relative transform transition-all duration-300 hover:scale-105 hover:rotate-6 shadow-xl">
                  <svg
                    className="w-12 h-12 text-[#8acc3d]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>
                </div>
              </div>

              {/* Text Content */}
              <div className="space-y-6">
                <h2 className="text-3xl font-bold leading-tight md:leading-snug">
                  <span className="bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                    Find Your Perfect connection in Tech: Collaborate, Innovate,
                    Succeed
                  </span>
                </h2>
                <p className="text-gray-600 text-xl leading-relaxed">
                  Let our AI do the heavylifting & help you find the perfect
                  techie for collaboration and innovation.
                </p>
              </div>
            </div>

            {/* Image Side */}
            <div className="flex justify-center items-center mt-8 md:mt-0">
              <div className="relative group w-full max-w-[400px]">
                <div className="absolute -inset-2 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-[2.5rem] blur opacity-20 group-hover:opacity-30 transition duration-500"></div>
                <div className="relative bg-white rounded-[2rem] shadow-2xl aspect-square">
                  <img
                    src="/1stSectionImage.jpg"
                    alt="Tech Connection"
                    className="rounded-[1.7rem] w-full h-full object-cover transform transition-all duration-500 group-hover:scale-[1.02]"
                  />
                  <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-[#8acc3d]/10 rounded-full blur-2xl"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* second section*/}
      <section className="pt-12 pb-16 mb-6 relative overflow-hidden">
        {/* Animated Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-20 left-10 !md:right-10 w-96 h-96 bg-gradient-to-r from-[#b0ff4b]/20 to-[#8acc3d]/20 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute bottom-20 right-10 !md:left-10 w-96 h-96 bg-gradient-to-r from-[#8acc3d]/20 to-[#b0ff4b]/20 rounded-full blur-3xl animate-pulse delay-1000"></div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="grid md:grid-cols-2 items-center justify-center">
            {/* Image Side */}
            <div className="flex justify-center items-center md:block hidden">
              <div className="relative group w-full max-w-[400px]">
                <div className="absolute -inset-2 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-[2.5rem] blur opacity-20 group-hover:opacity-30 transition duration-500"></div>
                <div className="relative bg-white rounded-[2rem] shadow-2xl aspect-square">
                  <img
                    src="/2ndSectionImage.jpg"
                    alt="Tech Networking"
                    className="rounded-[1.7rem] w-full h-full object-cover transform transition-all duration-500 group-hover:scale-[1.02]"
                  />
                  <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-[#8acc3d]/10 rounded-full blur-2xl"></div>
                </div>
              </div>
            </div>

            {/* Content Side */}
            <div className="relative z-10 space-y-10 md:-ml-20">
              {/* Icon Container with 3D effect */}
              <div className="relative group">
                <div className="w-24 h-24 bg-white backdrop-blur-xl rounded-3xl flex items-center justify-center relative transform transition-all duration-300 hover:scale-105 hover:rotate-6 shadow-xl">
                  <svg
                    className="w-12 h-12 text-[#8acc3d]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
              </div>

              {/* Text Content */}
              <div className="space-y-6">
                <h2 className="text-3xl font-bold leading-tight md:leading-snug md:w-[40rem]">
                  <span className="bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                    Tired of Unproductive Networking? Let AI Match You with the
                    Right Tech Partners
                  </span>
                </h2>
                <p className="text-gray-600 text-xl leading-relaxed">
                  Linkedin & other platforms are too vague. We are specific to
                  tech only.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center block md:hidden mt-8">
              <div className="relative group w-full max-w-[400px]">
                <div className="absolute -inset-2 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-[2.5rem] blur opacity-20 group-hover:opacity-30 transition duration-500"></div>
                <div className="relative bg-white rounded-[2rem] shadow-2xl aspect-square">
                  <img
                    src="/2ndSectionImage.jpg"
                    alt="Tech Networking"
                    className="rounded-[1.7rem] w-full h-full object-cover transform transition-all duration-500 group-hover:scale-[1.02]"
                  />
                  <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-[#8acc3d]/10 rounded-full blur-2xl"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* third section*/}
      <section className="pt-12 pb-16 relative overflow-hidden">
        {/* Animated Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-20 left-10 w-96 h-96 bg-gradient-to-r from-[#b0ff4b]/20 to-[#8acc3d]/20 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute bottom-20 right-10 w-96 h-96 bg-gradient-to-r from-[#8acc3d]/20 to-[#b0ff4b]/20 rounded-full blur-3xl animate-pulse delay-1000"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="grid md:grid-cols-2 items-center">
            {/* Content Side */}
            <div className="relative z-10 space-y-10">
              {/* Icon Container with 3D effect */}
              <div className="relative group">
                <div className="w-24 h-24 bg-white backdrop-blur-xl rounded-3xl flex items-center justify-center relative transform transition-all duration-300 hover:scale-105 hover:rotate-6 shadow-xl">
                  <svg
                    className="w-12 h-12 text-[#8acc3d]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    {/* Code Symbol */}
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    />
                  </svg>
                </div>
              </div>

              {/* Text Content */}
              <div className="space-y-6">
                <h2 className="text-3xl font-bold leading-tight md:leading-snug md:w-[40rem]">
                  <span className="bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                    From Machine Learning Experts to Full-Stack Developers:
                    <br className="block md:hidden"></br>Find Your Ideal Match
                  </span>
                </h2>
                <p className="text-gray-600 text-xl leading-relaxed">
                  Find professionals with varied skill sets, experience levels &
                  <br className="hidden md:block"></br> wider networking goals.
                </p>
              </div>
            </div>

            {/* Image Side */}
            <div className="flex justify-center items-center mt-8 md:mt-0">
              <div className="relative group w-full max-w-[400px]">
                <div className="absolute -inset-2 bg-gradient-to-r from-[#b0ff4b] to-[#8acc3d] rounded-[2.5rem] blur opacity-20 group-hover:opacity-30 transition duration-500"></div>
                <div className="relative bg-white rounded-[2rem] shadow-2xl aspect-square">
                  <img
                    src="/3rdSectionImage.jpg"
                    alt="Tech Connection"
                    className="rounded-[1.7rem] w-full h-full object-cover transform transition-all duration-500 group-hover:scale-[1.02]"
                  />
                  <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-[#8acc3d]/10 rounded-full blur-2xl"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FeatureSection;
