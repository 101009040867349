import React, { useEffect, useState } from "react";
import "./Chat.css";
import app_config from "../../config";
import { useParams, useNavigate } from "react-router-dom";
import { useSocket } from "../../context/SocketProvider";

const StartupChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const url = app_config.apiurl;
  const socket = useSocket();
  const { investorid } = useParams();
  const currentUser = JSON.parse(sessionStorage.getItem("investor"));

  const fetchChats = async (keyword = "") => {
    const token = sessionStorage.getItem("token");
    const res = await fetch(`${url}/chat/${investorid}/messages`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const chatsData = await res.json();
    console.log(chatsData);
    if (chatsData.recieverLastName) {
      setReceiverName(
        chatsData.recieverFirstName + " " + chatsData.recieverLastName,
      );
    } else {
      setReceiverName(chatsData.recieverFirstName);
    }

    if (keyword) {
      setMessageList(
        chatsData.result.filter((msg) =>
          msg.message.toLowerCase().includes(keyword.toLowerCase()),
        ),
      );
    } else {
      setMessageList(chatsData.result);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const [messageList, setMessageList] = useState([]);
  const [receiverName, setReceiverName] = useState("");
  const [inputText, setInputText] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const search = async () => {
    fetchChats(searchKeyword);
  };

  const saveData = async (formdata) => {
    const res = await fetch(
      `${app_config.apiurl}/chat/${investorid}/messages`,
      {
        method: "POST",
        body: JSON.stringify(formdata),
        headers: { "Content-Type": "application/json" },
      },
    );

    console.log(res.status);
  };

  const sendMessage = () => {
    if (!inputText.trim()) return;
    const temp = {
      message: inputText,
    };

    // sending msg to backend
    socket.emit("sendmsg", temp);

    setMessageList([...messageList, temp]);
    setInputText("");
    saveData(temp);
  };

  useEffect(() => {
    if (!socket) return;

    socket.emit("addtocontact", currentUser._id);

    socket.on("recmsg", (data) => {
      setMessageList([...messageList, data]);
    });
    return () => {
      socket.off("recmsg");
    };
  }, [socket]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#b0ff4b",
          height: "40vh",
          marginBottom: "-300px",
        }}
      >
        {" "}
      </div>

      <div className="container d-flex flex-column justify-content-center align-items-center mt-8 p-5 ">
        <div className="card h-[40rem] w-[21rem] md:h-[31rem] md:w-[36rem]">
          <div
            className="card-header bg-success flex items-center justify-around py-4 w-[21rem] md:w-[36rem]"
            style={{ color: "white", marginLeft: "0" }}
          >
            <div className="col-md-2">
              <button onClick={() => navigate(-1)}>
                <i className="fa-solid fa-arrow-left text-xl"></i>
              </button>
            </div>

            <div className="col-md-8">
              {receiverName ? (
                <p className="m-0 h4 text-center">{receiverName}</p>
              ) : (
                <p className="m-0 h4 text-center">Loading chat</p>
              )}
            </div>
            <div className="col-md-2">
              <div>
                <button
                  onClick={handleClick}
                  className="m-0 float-end"
                  style={{ border: "none", background: "none" }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/225/225287.png"
                    style={{ width: "20px" }}
                    alt="icon"
                  />
                </button>
              </div>
            </div>
          </div>
          {isOpen && (
            <div
              className="col-md-8 input-group my-2 px-3 "
              style={{ maxWidth: "600px" }}
            >
              <input
                className="form-control p-3"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button
                className="btn btn-primary input-group-append bg-success"
                style={{ color: "white" }}
                onClick={search}
              >
                Search
              </button>
            </div>
          )}
          <div className="card-body chat-body  h-[31rem] w-[21rem] md:w-[36rem]">
            {messageList.map((obj) => (
              <>
                <p
                  className="m-0 ms-3"
                  style={{ fontSize: "14px", color: "#b0ff4b" }}
                >
                  {obj.sentBy !== currentUser._id ? obj.name : ""}
                </p>
                <div
                  className={
                    obj.sentBy === currentUser._id ? "msg-sent" : "msg-rec"
                  }
                >
                  <p className="m-0 text-black">{obj.message}</p>
                  <p
                    className="m-0 float-end text-black"
                    style={{ fontSize: 10 }}
                  >
                    {new Date(obj.date).toLocaleDateString()}
                    {"    "}
                    {new Date(obj.date).toLocaleTimeString()}
                  </p>
                </div>
              </>
            ))}
          </div>
          <div className="card-footer w-[21rem] md:w-[36rem]">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                value={inputText}
              />
              <button className="btn btn-success" onClick={sendMessage}>
                <i className="fas fa-paper-plane"></i>   Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartupChat;
