import { Drawer, IconButton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SideBarActionMenuItem, SideBarNavMenuItem } from "./MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

const drawerWidth = 220;
const drawerWidthMobileView = 285;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DashboardSideBar = ({
  menuItems,
  isMobileView,
  sideBarOpen,
  onSideMenuClick,
}) => {
  const location = useLocation();

  return (
    <Drawer
      sx={{
        width: isMobileView ? drawerWidthMobileView : drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isMobileView ? drawerWidthMobileView : drawerWidth,
          backgroundColor: "#fff",
          boxSizing: "border-box",
        },
        ...(isMobileView && {
          position: "fixed",
          zIndex: 1300,
        }),
      }}
      variant={isMobileView ? "temporary" : "persistent"}
      anchor="left"
      open={sideBarOpen}
      onClose={onSideMenuClick}
    >
      <DrawerHeader className="flex items-center md:mb-6">
        {isMobileView && (
          <>
            <div className="ml-7 md:ml-0 h-28">
              <img
                src="\logo.png"
                className="ml-[-40px] h-32  w-[17rem]"
                alt="Kreedify logo"
                loading="lazy"
              />
            </div>
            <IconButton onClick={onSideMenuClick}>
              <CloseIcon className="!h-7 !w-7" />
            </IconButton>
          </>
        )}
      </DrawerHeader>

      {menuItems.map((menu, index) =>
        // Only show last item on mobile or show all other items
        isMobileView || index !== menuItems.length - 1 ? (
          menu.link ? (
            <SideBarNavMenuItem
              key={menu.id}
              menu={menu}
              currentPathName={location.pathname}
              onClick={isMobileView ? onSideMenuClick : null}
            />
          ) : (
            <SideBarActionMenuItem
              key={menu.id}
              menu={menu}
              currentPathName={location.pathname}
              onClick={isMobileView ? onSideMenuClick : null}
            />
          )
        ) : null,
      )}
    </Drawer>
  );
};

export default DashboardSideBar;
