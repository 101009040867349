import React, { useState } from "react";
import {
  startupIndustries,
  industryFocusOption,
  startupStages,
  scoutSupportedStartupStages,
  networkTypes,
  experienceHelpingStartups,
} from "../../Constants";
import { countries } from "../commons/countries";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import FilterOptions from "../commons/filterOptions";
import debounce from "lodash.debounce";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      sx={{
        alignItems: "end",
      }}
    />
  );
});

const FilterTabs = ({
  listType,
  labels,
  setStartupStageFilteVal,
  startupStageFilteVal,
  setRevenueStageFilteVal,
  revenueStageFilteVal,
  setIndustryFocusFilteVal,
  industryFocusFilteVal,
  setLocationFilterVal,
  locationFilterVal,
  setRaisedAmountFilteVal,
  raisedAmountFilteVal,
  setStartupStageFilterVal,
  startupStageFilterVal,
  setIndustryFocusFilterVal,
  industryFocusFilterVal,
  setInvestorLocationFilterVal,
  investorlocationFilterVal,
  setNetworkTypeFilterVal,
  networkTypeFilterVal,
  experienceFilterVal,
  setExperienceFilterVal,
  search,
  clearFilters,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [locationInput, setLocationInput] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [isLocationField, setIsLocationField] = useState(false); // Flag to control location field input
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Check if the selected tab is for location filtering
    if (listType === "StartUpList" && newValue === 3) {
      setIsLocationField(true);
    } else if (listType === "InvestorList" && newValue === 2) {
      setIsLocationField(true);
    } else {
      setIsLocationField(false);
    }
  };

  const handleLocationInputChange = (e) => {
    const input = e.target.value;
    setLocationInput(input);

    const debouncedFilterCountries = debounce((input) => {
      const filtered = countries.filter((cc) =>
        cc.name.toLowerCase().includes(input.toLowerCase()),
      );
      setFilteredCountries(filtered);
    }, 300);

    debouncedFilterCountries(input);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="px-4 pt-2">
            <div>{children}</div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="block -mt-14 ml-72 md:hidden">
      <div className="flex px-3 mb-3 ">
        <button
          onClick={handleClickOpen}
          className="btn p-2 bg-black text-white"
        >
          Filter
        </button>
        <button
          onClick={clearFilters}
          className="btn p-2 ml-1 bg-black text-white"
        >
          Clear
        </button>
      </div>
      <Dialog
        PaperProps={{
          sx: {
            width: "100%",
            maxHeight: "80%",
            minHeight: "50%",
            margin: 0,
          },
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className="flex justify-between px-4 py-2 border-b border-[#000]">
          <div>
            <h2 className="text-2xl">Filters</h2>
          </div>
          <button onClick={handleClose} className="btn p-2 bg-[#b0ff4b]">
            Close
          </button>
        </div>
        <div>
          <div>
            <div className="flex">
              <div className="w-[30%]">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {labels.map((item, i) => (
                    <Tab
                      sx={{
                        backgroundColor: value === i ? "#fff" : "#f1f2f3",
                      }}
                      label={item}
                      {...a11yProps(i)}
                    />
                  ))}
                </Tabs>
              </div>
              <div className="w-[70%] mb-[2.8rem]">
                {listType === "StartUpList" ? (
                  // StartUpList Options
                  <>
                    <TabPanel value={value} index={0}>
                      <FilterOptions
                        options={startupStages.map((stg) => {
                          return { label: stg.text, val: stg.value };
                        })}
                        onSelectionChange={(stg) =>
                          setStartupStageFilteVal(stg)
                        }
                        selectedOptions={startupStageFilteVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <FilterOptions
                        options={[
                          { label: "Funded", val: "Funded" },
                          { label: "Bootstrapped", val: "Bootstrapped" },
                        ]}
                        onSelectionChange={(stg) =>
                          setRevenueStageFilteVal(stg)
                        }
                        selectedOptions={revenueStageFilteVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <FilterOptions
                        options={startupIndustries.map((option) => {
                          return { label: option.text, val: option.value };
                        })}
                        onSelectionChange={(ind) =>
                          setIndustryFocusFilteVal(ind)
                        }
                        selectedOptions={industryFocusFilteVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      {isLocationField && (
                        <input
                          type="text"
                          value={locationInput}
                          onChange={handleLocationInputChange}
                          placeholder="Search location"
                          className="form-control w-[90%] mx-2 px-2 py-2 border border-gray-300 rounded-md mb-2"
                        />
                      )}
                      <FilterOptions
                        options={filteredCountries.map((cc) => {
                          return { label: cc.name, val: cc.value };
                        })}
                        onSelectionChange={(val) => setLocationFilterVal(val)}
                        selectedOptions={locationFilterVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <input
                        type="number"
                        className="form-control w-[13rem]"
                        placeholder="Current Raised Amount"
                        value={raisedAmountFilteVal}
                        onChange={(e) =>
                          setRaisedAmountFilteVal(e.target.value)
                        }
                      />
                    </TabPanel>
                  </>
                ) : (
                  // InvestorList Options
                  <>
                    <TabPanel value={value} index={0}>
                      <FilterOptions
                        options={scoutSupportedStartupStages.map((stg) => {
                          return { label: stg.text, val: stg.value };
                        })}
                        onSelectionChange={(val) =>
                          setStartupStageFilterVal(val)
                        }
                        selectedOptions={startupStageFilterVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <FilterOptions
                        options={industryFocusOption.map((ind) => {
                          return { label: ind.text, val: ind.value };
                        })}
                        onSelectionChange={(val) =>
                          setIndustryFocusFilterVal(val)
                        }
                        selectedOptions={industryFocusFilterVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {isLocationField && (
                        <input
                          type="text"
                          value={locationInput}
                          onChange={handleLocationInputChange}
                          placeholder="Search location"
                          className="form-control w-[90%] mx-2 px-2 py-2 border border-gray-300 rounded-md mb-2"
                        />
                      )}
                      <FilterOptions
                        options={filteredCountries.map((cc) => {
                          return { label: cc.name, val: cc.value };
                        })}
                        onSelectionChange={(val) =>
                          setInvestorLocationFilterVal(val)
                        }
                        selectedOptions={investorlocationFilterVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <FilterOptions
                        options={networkTypes.map((nt) => {
                          return { label: nt.text, val: nt.value };
                        })}
                        onSelectionChange={(val) =>
                          setNetworkTypeFilterVal(val)
                        }
                        selectedOptions={networkTypeFilterVal}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <FilterOptions
                        options={experienceHelpingStartups.map((eh) => {
                          return { label: eh.text, val: eh.value };
                        })}
                        onSelectionChange={(val) => setExperienceFilterVal(val)}
                        selectedOptions={experienceFilterVal}
                      />
                    </TabPanel>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="shadow-lg w-full flex justify-end fixed bottom-0 right-0 px-4 py-2">
            <button
              onClick={search}
              className="inline-flex justify-center w-[12rem] md:w-32 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-black text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FilterTabs;
