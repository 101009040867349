import { MDBTextArea } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import app_config from "../../config";

const LeanCanvasModel = () => {
  const url = app_config.apiurl;
  const token = sessionStorage.getItem("token");
  const [canvasData, setCanvasData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // State to track edit mode

  const isMobile = window.innerWidth <= 768;

  // Updated Yup validation schema without required fields
  const LeanCanvasSchema = Yup.object().shape({
    problem: Yup.string(),
    solution: Yup.string(),
    valueProposition: Yup.string(),
    keyMetrics: Yup.string(),
    marketingChannels: Yup.string(),
    revenueModel: Yup.string(),
    costStructure: Yup.string(),
    team: Yup.string(),
    customerSegments: Yup.string(),
  });

  // Fetch existing Lean Canvas data
  const fetchLeanCanvas = async () => {
    const res = await fetch(`${url}/canvas/fetch`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      console.log("data", data);
      setCanvasData(data.result);
    }
  };

  useEffect(() => {
    fetchLeanCanvas();
  }, []);

  // Function to handle form submission (update or save)
  const handleSubmitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    console.log("canvasData", canvasData);
    const apiUrl = canvasData ? `${url}/canvas/update` : `${url}/canvas/new`;
    console.log(apiUrl);

    const res = await fetch(apiUrl, {
      method: canvasData ? "PUT" : "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setSubmitting(false);

    if (res.status === 200 || res.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Saved Successfully",
      });
      fetchLeanCanvas(); // Refresh canvas data after update
      setIsEditMode(false); // Disable edit mode after saving
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save, please try again.",
      });
    }
  };

  return (
    <>
      {/* Header Section */}
      <div className="justify-center">
        <div className="flex flex-col md:ml-10 ml-6">
          <h1 className="text-black pt-12 text-4xl font-semibold sm:mt-0">
            Lean Canvas Model
          </h1>
          <br />
          <p className="text-black md:w-[55rem]">
            As an entrepreneur, one of the most important tasks you can perform
            is getting your idea(s) out from your head into a tangible format so
            that you can communicate that with others. In the past, this usually
            meant a well-researched business plan, that would usually take weeks
            (more like months) to create.<br></br>
            <br></br>
            Tech entrepreneurs face the challenge of creating too many features,
            working on a problem that does not exist, being too technical
            focused, not being able to find a non-tech partner and more.{" "}
            <br></br>
            The lean canvas model will help you to bring more structure in terms
            of truly refining your idea & working on a solution for a real
            problem. Use the below boxes to add information about your startup
            idea.
          </p>
        </div>

        {/* Form Section */}
        <div className="md:ml-10 ml-6 md:mt-8 mt-6 mb-5 mr-6">
          <Formik
            initialValues={{
              problem: canvasData?.problem || "",
              solution: canvasData?.solution || "",
              valueProposition: canvasData?.valueProposition || "",
              keyMetrics: canvasData?.keyMetrics || "",
              marketingChannels: canvasData?.marketingChannels || "",
              revenueModel: canvasData?.revenueModel || "",
              costStructure: canvasData?.costStructure || "",
              team: canvasData?.team || "",
              customerSegments: canvasData?.customerSegments || "",
            }}
            validationSchema={LeanCanvasSchema}
            enableReinitialize={true}
            onSubmit={handleSubmitForm}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Problem and Solution Fields */}
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Problem"
                      id="problem"
                      value={values.problem}
                      onChange={handleChange}
                      name="problem"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.problem && touched.problem && (
                      <div className="text-danger">{errors.problem}</div>
                    )}
                  </div>

                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Solution"
                      id="solution"
                      value={values.solution}
                      onChange={handleChange}
                      name="solution"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.solution && touched.solution && (
                      <div className="text-danger">{errors.solution}</div>
                    )}
                  </div>
                </div>

                {/* Value Proposition and Key Metrics Fields */}
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Value Proposition"
                      id="valueProposition"
                      value={values.valueProposition}
                      onChange={handleChange}
                      name="valueProposition"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.valueProposition && touched.valueProposition && (
                      <div className="text-danger">
                        {errors.valueProposition}
                      </div>
                    )}
                  </div>

                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Key Metrics"
                      id="keyMetrics"
                      value={values.keyMetrics}
                      onChange={handleChange}
                      name="keyMetrics"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.keyMetrics && touched.keyMetrics && (
                      <div className="text-danger">{errors.keyMetrics}</div>
                    )}
                  </div>
                </div>

                {/* Marketing Channels and Revenue Model Fields */}
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Marketing Channels"
                      id="marketingChannels"
                      value={values.marketingChannels}
                      onChange={handleChange}
                      name="marketingChannels"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.marketingChannels && touched.marketingChannels && (
                      <div className="text-danger">
                        {errors.marketingChannels}
                      </div>
                    )}
                  </div>

                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Revenue Model"
                      id="revenueModel"
                      value={values.revenueModel}
                      onChange={handleChange}
                      name="revenueModel"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.revenueModel && touched.revenueModel && (
                      <div className="text-danger">{errors.revenueModel}</div>
                    )}
                  </div>
                </div>

                {/* Cost Structure and Team Fields */}
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Cost Structure"
                      id="costStructure"
                      value={values.costStructure}
                      onChange={handleChange}
                      name="costStructure"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.costStructure && touched.costStructure && (
                      <div className="text-danger">{errors.costStructure}</div>
                    )}
                  </div>

                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Team"
                      id="team"
                      value={values.team}
                      onChange={handleChange}
                      name="team"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.team && touched.team && (
                      <div className="text-danger">{errors.team}</div>
                    )}
                  </div>
                </div>

                {/* Customer Segments Field */}
                <div className="flex flex-col">
                  <div className="form-outline mb-4 md:w-1/3">
                    <MDBTextArea
                      label="Customer Segments"
                      id="customerSegments"
                      value={values.customerSegments}
                      onChange={handleChange}
                      name="customerSegments"
                      rows={isMobile ? 4 : 2} // Conditionally set rows based on screen size
                      disabled={!isEditMode}
                    />
                    {errors.customerSegments && touched.customerSegments && (
                      <div className="text-danger">
                        {errors.customerSegments}
                      </div>
                    )}
                  </div>
                </div>

                {/* Buttons Section */}
                <div className="flex flex-row md:space-x-4 justify-between md:justify-start mt-4 md:ml-40">
                  <button
                    type="button" // Prevents default form submission
                    onClick={() => setIsEditMode(!isEditMode)}
                    className={`bg-black text-white rounded-md py-2 md:w-1/4 w-[125px]
                    ${isEditMode ? "opacity-50 cursor-not-allowed" : ""}`}
                    disabled={isEditMode}
                  >
                    Edit
                  </button>

                  <button
                    disabled={!isEditMode || isSubmitting}
                    type="submit"
                    className={`bg-skincolor text-black px-5 py-2 rounded-md md:w-1/4 w-[125px] ${
                      !isEditMode ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default LeanCanvasModel;
