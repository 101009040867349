import React, { useEffect, useState } from "react";
import app_config from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { checkProfileImageExist, formatMessageTime } from "../../util";
import { CircularProgress } from "@mui/material";
import { IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AlphabetAvatar from "../AlphabetAvatar";
import { useSocket } from "../../context/SocketProvider";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState("ALL");
  const profilesPerPage = 10; // Number of notifications per page

  const socket = useSocket();

  const url = app_config.apiurl;
  const token = sessionStorage.getItem("token");
  let currentUser = JSON.parse(sessionStorage.getItem("startup"));
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch(`${url}/notifications/fetchall`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(data);
    if (response.ok && data.status === "success") {
      setNotifications(data.result);
      setLoading(false);
    } else {
      setNotifications([]);
    }
  };
  const markNotificationsAsRead = async () => {
    const response = await fetch(`${url}/notifications/mark-as-read`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "success") {
      let currentUser = JSON.parse(sessionStorage.getItem("startup"));
      currentUser.unreadNotifications = 0;
      sessionStorage.setItem("startup", JSON.stringify(currentUser));
    }
  };

  useEffect(() => {
    if (!socket) return;

    socket.on(`notification-${currentUser._id}`, () => {
      fetchData();
    });

    return () => socket.off(`notification-${currentUser._id}`);
  }, [socket, currentUser._id]);

  useEffect(() => {
    fetchData();
    markNotificationsAsRead();
  }, []);

  const handleConnectionRequest = async (requestId, approve) => {
    const res = await fetch(
      `${app_config.apiurl}/connection-requests/${requestId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ approve }),
      },
    );
    if (res.ok) {
      fetchData();
    }
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const getFilteredNotifications = () => {
    if (filterType === "ALL") {
      return notifications;
    }
    return notifications.filter(
      (notification) => notification.type === filterType,
    );
  };

  const displayNotifications = () => {
    const filteredNotifications = getFilteredNotifications();
    const startIndex = (currentPage - 1) * profilesPerPage;
    const endIndex = startIndex + profilesPerPage;
    const currentNotifications = filteredNotifications.slice(
      startIndex,
      endIndex,
    );

    return currentNotifications.map((notification) => (
      <NotificationCard key={notification._id} notification={notification} />
    ));
  };

  const renderPagination = () => {
    const filteredNotifications = getFilteredNotifications();
    const totalPages = Math.ceil(
      filteredNotifications.length / profilesPerPage,
    );
    if (totalPages <= 1) return null;

    const generatePageNumbers = () => {
      const visiblePages = [];
      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) visiblePages.push(i);
      } else {
        visiblePages.push(1);
        if (currentPage > 3) visiblePages.push("...");
        for (
          let i = Math.max(currentPage - 1, 2);
          i <= Math.min(currentPage + 1, totalPages - 1);
          i++
        ) {
          visiblePages.push(i);
        }
        if (currentPage < totalPages - 2) visiblePages.push("...");
        visiblePages.push(totalPages);
      }
      return visiblePages;
    };

    const pageNumbers = generatePageNumbers();

    return (
      <div className="flex  text-xs md:text-base">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`px-1.5 md:px-2 py-1 mx-0.5 border rounded bg-white text-black ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span
              key={`ellipsis-${index}`}
              className="px-1.5 md:px-2 py-1 mx-1"
            >
              ...
            </span>
          ) : (
            <button
              key={number}
              onClick={() => setCurrentPage(number)}
              className={`px-2 py-1 mx-1 border rounded ${
                currentPage === number
                  ? "bg-black text-white"
                  : "bg-white text-black"
              }`}
            >
              {number}
            </button>
          ),
        )}
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const NotificationCard = ({ notification }) => {
    const getNotificationIcon = (type, status) => {
      switch (type) {
        case "CONNECTION_REQUEST":
          if (status === "rejected") {
            return (
              <div className="p-2 bg-purple-100 rounded-full">
                <svg
                  className="w-3 h-3  md:w-6 md:h-6 text-red-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <line
                    x1="8"
                    y1="8"
                    x2="16"
                    y2="16"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <line
                    x1="8"
                    y1="16"
                    x2="16"
                    y2="8"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            );
          }
          return (
            <div className="p-2 bg-blue-100 rounded-full">
              <svg
                className="w-3 h-3  md:w-6 md:h-6 text-blue-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
          );
        case "CONNECTION_APPROVED":
          return (
            <div className="p-2 bg-green-100 rounded-full">
              <svg
                className="w-3 h-3  md:w-6 md:h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          );
        case "verification":
          return (
            <div className="p-2 bg-green-100 rounded-full">
              <svg
                className="w-3 h-3  md:w-6 md:h-6 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          );
        case "NEW_MESSAGE":
          return (
            <div className="p-2 bg-purple-100 rounded-full">
              <svg
                className="w-3 h-3  md:w-6 md:h-6 text-purple-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                />
              </svg>
            </div>
          );
        case "CONNECTION_REJECTED":
          return (
            <div className="p-2 bg-purple-100 rounded-full">
              <svg
                className="w-3 h-3  md:w-6 md:h-6 text-red-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <line
                  x1="8"
                  y1="8"
                  x2="16"
                  y2="16"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <line
                  x1="8"
                  y1="16"
                  x2="16"
                  y2="8"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </div>
          );
        case "NEW_RECOMMENDATIONS":
          return (
            <div className="p-2 bg-yellow-100 rounded-full">
              <svg
                className="w-3 h-3  md:w-6 md:h-6 text-yellow-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                />
              </svg>
            </div>
          );
        default:
          return null;
      }
    };

    const handleNotificationClick = () => {
      let url = "";
      if (
        notification.type === "CONNECTION_REQUEST" ||
        notification.type === "CONNECTION_APPROVED" ||
        notification.type === "CONNECTION_REJECTED"
      ) {
        url = `/startup/introai/${notification.from._id}`;
      } else if (notification.type === "NEW_RECOMMENDATIONS") {
        url = "/startup/introai";
      } else if (notification.type === "NEW_MESSAGE") {
        url = "/startup/inbox";
      } else if (notification.type === "verification") {
        url = `/startup/myprofile/${notification.from._id}`;
      }
      navigate(url);
    };

    if (notification.from === null) {
      return null;
    }

    if (
      notification.type === "NEW_MESSAGE" ||
      notification.type === "NEW_RECOMMENDATIONS"
    ) {
      return (
        <div
          className={`flex hover:bg-gray-200/50 px-3 items-center py-2.5  cursor-pointer text-sm border-b border-gray-300 ${notification.read ? "" : "bg-blue-50"}`}
          onClick={handleNotificationClick}
        >
          <div className="flex-shrink-0 mr-4">
            {getNotificationIcon(
              notification.type,
              notification.connectionStatus,
            )}
          </div>

          <div className="flex-grow items-center justify-between flex ">
            <p
              className={`text-gray-600 text-sm max-w-[70%] md:max-w-full  md:text-sm mt-1 ${notification.type === "NEW_MESSAGE" && notification.read ? "font-bold" : "font-semibold"}`}
            >
              {notification.message}
            </p>
            <div className="flex items-center  ">
              <span className="text-[10px] md:text-xs text-gray-500">
                {formatMessageTime(notification.createdAt)}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`flex hover:bg-gray-200/50 px-3 items-center py-3  cursor-pointer   border-b border-gray-300 ${notification.read ? "" : "bg-blue-50"}`}
        onClick={handleNotificationClick}
      >
        <div className="flex-shrink-0 mr-2 md:mr-4">
          {getNotificationIcon(
            notification.type,
            notification.connectionStatus,
          )}
        </div>
        {
          <div className="w-12">
            {checkProfileImageExist(currentUser.startupimage) ? (
              <img
                className="w-10 h-10 md:w-12 md:h-12 rounded-full"
                src={notification.from.startupimage}
                alt="avatar"
              />
            ) : (
              <AlphabetAvatar
                alphabet={notification.from.firstName.charAt(0)}
                className="!h-10 !w-10 md:h-12 md:w-12"
              />
            )}
          </div>
        }
        <div className="flex-grow  ml-2 md:ml-4">
          <div className="flex items-center justify-between ">
            <Link
              to={`/startup/introai/${notification.from._id}`}
              className="text-sm max-w-[5rem] truncate  md:text-base font-semibold hover:text-blue-600"
            >
              {notification.from.firstName} {notification.from.lastName}
            </Link>
            <span className="text-[10px] md:text-xs text-gray-500">
              {formatMessageTime(notification.createdAt)}
            </span>
          </div>
          <p className="text-gray-600 text-sm  mt-1">
            {notification.connectionStatus === "approved" &&
            notification.type === "CONNECTION_REQUEST"
              ? `Connected with ${notification.from.firstName}`
              : notification.type === "CONNECTION_REQUEST" &&
                  notification.connectionStatus === "rejected"
                ? "This request no longer exists"
                : notification.message}
          </p>
          {notification.type === "CONNECTION_REQUEST" &&
            notification.connectionStatus === "pending" && (
              <div className="flex space-x-2">
                {/* Icon buttons for mobile */}
                <div className="md:hidden">
                  <IconButton
                    aria-label="accept"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleConnectionRequest(
                        notification.connectionRequestId,
                        true,
                      );
                    }}
                  >
                    <CheckCircleIcon
                      fontSize="large"
                      className="text-skincolor"
                    />
                  </IconButton>
                  <IconButton
                    aria-label="deny"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleConnectionRequest(
                        notification.connectionRequestId,
                        false,
                      );
                    }}
                  >
                    <CancelIcon fontSize="large" className="text-deny" />
                  </IconButton>
                </div>

                {/* Regular buttons for desktop */}
                <div className="hidden md:flex my-4 w-full">
                  <button
                    className="bg-skincolor text-black px-2 py-1 rounded w-20 h-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleConnectionRequest(
                        notification.connectionRequestId,
                        true,
                      );
                    }}
                  >
                    Accept
                  </button>
                  <button
                    className="bg-deny/80 text-black px-2 py-1 rounded w-20 h-10 ml-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleConnectionRequest(
                        notification.connectionRequestId,
                        false,
                      );
                    }}
                  >
                    Deny
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <div className=" max-w-4xl md:ml-5 px-2 md:px-16 py-10 text-sm md:text-base">
      <div className="flex items-center justify-start mb-4 w-full ml-2 md:ml-0">
        <h1 className="text-4xl font-bold  text-black">Notifications</h1>
      </div>
      <div className="flex flex-col md:flex-row  gap-y-4 md:items-center md:justify-between mb-4">
        <div className="">
          <label htmlFor="filter" className="mr-2 md:ml-0 ml-2">
            Filter by type:
          </label>
          <select
            id="filter"
            value={filterType}
            onChange={handleFilterChange}
            className="border rounded px-2 py-1"
          >
            <option value="ALL">All</option>
            <option value="CONNECTION_REQUEST">Connection Request</option>
            <option value="CONNECTION_APPROVED">Connection Approved</option>
            <option value="CONNECTION_REJECTED">Connection Rejected</option>
            <option value="NEW_MESSAGE">New Message</option>
            <option value="NEW_RECOMMENDATIONS">New Recommendations</option>
            <option value="verification">Verification</option>
          </select>
        </div>
        {renderPagination()}
      </div>

      {loading ? (
        // Show loading spinner while fetching data
        <div className="text-center mt-64">
          <CircularProgress color="success" />
        </div>
      ) : (
        <>
          {notifications.length === 0 ? (
            <div className="text-center py-12  rounded-lg">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No notifications
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                You're all caught up!
              </p>
            </div>
          ) : (
            <div>{displayNotifications()}</div>
          )}
        </>
      )}
    </div>
  );
}
