import React from "react";
import { Link } from "react-router-dom";
import AlphabetAvatar from "../AlphabetAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isAvatarImageAvailable } from "../../util.js";

const MyNetwork = ({ approvedRequests }) => {
  return (
    <div className="py-2 px-2 md:-mt-16 md:!py-10 mb-4">
      {approvedRequests.length === 0 ? (
        <p className="text-center mt-4 mb-4">yet to create your network.</p>
      ) : (
        approvedRequests.map((request) => (
          <div
            key={request._id}
            className="flex flex-col md:-ml-8 md:w-[40rem] gap-2 py-4 md:py-8 px-3 md:px-0 sm:p-6 bg-white mx-3 md:mx-auto rounded-xl shadow-md mb-3 md:mb-5"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex items-center mr-4">
                  {isAvatarImageAvailable(request.sender.startupimage) ? (
                    <img
                      className="h-16 w-16 md:h-16 md:w-16 rounded-full"
                      src={request.sender.startupimage}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={request.sender.firstName.charAt(0)}
                      className="!h-16 !w-16 md:h-16 md:w-16"
                    />
                  )}
                </div>

                <div className="md:w-auto">
                  <h2 className="text-base md:text-2xl font-medium capitalize">
                    {request.sender.firstName}
                  </h2>
                </div>
              </div>

              <div className="flex-shrink-0 md:hidden">
                <Link
                  className="bg-white border-2 border-black p-2 rounded-full flex items-center justify-center h-7 w-7 md:h-auto md:w-auto md:p-2"
                  to={"/investor/startupdetails/" + request.sender._id}
                >
                  <ArrowForwardIcon />
                </Link>
              </div>

              <div className="hidden md:block">
                <Link
                  className="bg-white border-2 border-black text-black p-2 rounded"
                  to={"/investor/startupdetails/" + request.sender._id}
                >
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MyNetwork;
