import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import app_config from "../../config";
import { CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { findCountryLabel } from "../commons/countries";
import { FaCheckCircle } from "react-icons/fa";
import AlphabetAvatar from "../AlphabetAvatar";

const IntroAI = () => {
  const [filteredStartupList, setFilteredStartupList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  let navigate = useNavigate();
  const profilesPerPage = 10;

  const url = app_config.apiurl;

  const token = sessionStorage.getItem("token");
  let currentUser = JSON.parse(sessionStorage.getItem("startup"));

  const fetchData = async (page = 1) => {
    setLoading(true);
    const response = await fetch(
      `${url}/introai/v2/introai_users/${currentUser._id}?page=${page}&limit=${profilesPerPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();

    console.log("data", data.users);

    if (response.ok && Array.isArray(data.users)) {
      setFilteredStartupList(data.users);
      setTotalPages(data.totalPages);
    } else {
      setFilteredStartupList([]); // Set to an empty array if no recommendations or invalid token
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const isAvatarImageAvailable = (imageUrl) =>
    imageUrl && imageUrl.trim().length > 0 && imageUrl !== "undefined";

  const displayList = () => {
    if (loading) {
      return (
        <div className="text-center mt-64">
          <CircularProgress color="success" />
        </div>
      );
    }
    const currentList = filteredStartupList;
    return currentList.map((startup, i) => {
      return (
        <div
          key={startup._id}
          className="bg-[#fff] rounded relative mb-4 shadow-md"
        >
          {i < 3 && currentPage === 1 && (
            <div className="bg-skincolor bg-opacity-60 px-2 py-1 rounded-md w-fit text-xs font-semibold absolute -top-3 right-3 z-0">
              New Intro
            </div>
          )}

          <div
            className={`p-2 flex flex-row py-3 items-center h-full ${i < 3 && currentPage === 1 && "border-2 border-skincolor"} border-opacity-60 bg-white z-20`}
          >
            <div className="col-md-4 w-[30%] flex flex-col gap-2 items-center justify-center">
              {isAvatarImageAvailable(startup.startupimage) ? (
                <img
                  className="px-1 py-1 w-24 h-24 rounded-full"
                  src={startup.startupimage}
                  alt="Startup"
                />
              ) : (
                <AlphabetAvatar alphabet={startup.firstName.charAt(0)} />
              )}
              <div>
                <Link
                  type="button"
                  className="text-xs md:text-sm rounded-md px-3 font-normal py-1 text-black bg-skincolor hover:bg-opacity-80"
                  to={`/startup/introai/${startup._id}`}
                >
                  View
                </Link>
              </div>
            </div>
            <div className="w-[70%] px-1 md:ml-16 lg:ml-1 text-xs md:text-sm">
              <h1 className="md:text-xl capitalize text-lg flex items-center gap-2">
                <strong className="max-w-[70%] truncate">
                  {startup.firstName}
                </strong>
                {startup.verificationStatus === "verified" && (
                  <FaCheckCircle className="text-green-600" size={"1rem"} />
                )}
              </h1>
              <p className="text-[#808080] mt-1">
                <strong>
                  {findCountryLabel(startup.location) || "Not Available"}
                </strong>
              </p>
              <div className="flex flex-row mt-2 items-start">
                <p className="font-bold">
                  Interested In:{" "}
                  <span className="ml-1 font-normal">
                    {startup.interested_in.length > 2
                      ? `${startup.interested_in.slice(0, 2).join(", ")} +${startup.interested_in.length - 2}`
                      : startup.interested_in.join(", ") || "Not Available"}
                  </span>
                </p>
              </div>
              <div className="flex flex-row mt-1 items-start">
                <p className="font-bold">
                  Support Required:{" "}
                  <span className="ml-1 font-normal">
                    {startup.support_needed.length > 2
                      ? `${startup.support_needed.slice(0, 2).join(", ")} +${startup.support_needed.length - 2}`
                      : startup.support_needed.join(", ") || "Not Available"}
                  </span>
                </p>
              </div>
              <div className="flex flex-row mt-1 items-start">
                <p className="font-bold">
                  Strongest Skills:{" "}
                  <span className="ml-1 font-normal">
                    {startup.strongest_skills.join(", ") || "Not Available"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const generatePageNumbers = () => {
      const visiblePages = [];
      if (totalPages <= 5) {
        // Show all pages if total pages are 5 or less
        for (let i = 1; i <= totalPages; i++) visiblePages.push(i);
      } else {
        // First page
        visiblePages.push(1);

        // Ellipsis before current page
        if (currentPage > 3) visiblePages.push("...");

        // Middle range (current page ±1)
        for (
          let i = Math.max(currentPage - 1, 2);
          i <= Math.min(currentPage + 1, totalPages - 1);
          i++
        ) {
          visiblePages.push(i);
        }

        // Ellipsis after current page
        if (currentPage < totalPages - 2) visiblePages.push("...");

        // Last page
        visiblePages.push(totalPages);
      }
      return visiblePages;
    };

    const pageNumbers = generatePageNumbers();

    return (
      <div className="flex justify-center mt-6 md:mt-10">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span key={`ellipsis-${index}`} className="px-2 py-1 mx-1">
              ...
            </span>
          ) : (
            <button
              key={number}
              onClick={() => setCurrentPage(number)}
              className={`px-2 py-1 mx-1 border rounded ${
                currentPage === number
                  ? "bg-black text-white"
                  : "bg-white text-black"
              }`}
            >
              {number}
            </button>
          ),
        )}
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          className={`px-2 py-1 mx-1 border rounded bg-white text-black ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  if (filteredStartupList.length === 0 && !loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col justify-center text-center md:-mt-60">
          <p className="text-lg text-gray-600 mb-2 mx-6 md:mx-0 md:w-[50rem]">
            Welcome to intro AI. Get connected with other professionals
            automatically and improve your network. To start getting intros,
            visit edit profile
          </p>
          <div className="flex justify-center">
            <button
              className="flex items-center gap-2 px-4 py-2 mt-2 bg-skincolor text-black font-medium rounded-lg"
              onClick={() =>
                navigate(
                  `/startup/myprofile/${currentUser._id}?tab=Register&subTab=intro`,
                )
              }
            >
              <i className="fas fa-edit"></i> Edit
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="h-2  md:h-16 justify-start ml-5 md:ml-0">
        <div className="flex flex-row justify-between items-center mb-10">
          <h1 className="text-black md:ml-7 pt-6 text-4xl font-semibold mt-3 sm:mt-0">
            Intro AI
          </h1>
          <button
            className="underline cursor-pointer bg-skincolor px-4 py-2 rounded-md mr-2 mt-8 md:hidden self-center"
            onClick={() =>
              navigate(
                `/startup/myprofile/${currentUser._id}?tab=Register&subTab=intro`,
              )
            }
          >
            <i className="fas fa-edit"></i> Edit
          </button>
        </div>
      </div>
      <>
        {filteredStartupList.length !== 0 && (
          <div className="flex flex-col mt-2 md:mt-16">
            <div className="mx-6 mt-4 hidden md:block max-w-[70%]">
              <h1>
                Get more intros to interesting people automatically. Check New
                Intros every Monday and Thursday
                <span
                  className="hidden md:inline  w-fit mt-16 cursor-pointer bg-skincolor px-4 py-2 rounded-md ml-4"
                  onClick={() =>
                    navigate(
                      `/startup/myprofile/${currentUser._id}?tab=Register&subTab=intro`,
                    )
                  }
                >
                  <i className="fas fa-edit"></i> Edit
                </span>
              </h1>
              <br />
              <div className="mt-2">
                <span>Your strongest skills are </span>
                <span className="block mt-3">
                  {/* {currentUser.strongest_skills.join(", ") || "Not Available"}{" "} */}
                  {currentUser.strongest_skills.map((skill, index) => {
                    return (
                      <span
                        className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                        key={index}
                      >
                        {skill}
                      </span>
                    );
                  })}
                </span>
                <br />
                <div className="mt-1">
                  <span>and you need support for </span>

                  <span className="mt-3 flex  flex-wrap gap-y-2 ">
                    {currentUser.support_needed.length > 2 ? (
                      <>
                        {currentUser.support_needed
                          .slice(0, 2)
                          .map((support, index) => (
                            <span
                              className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                              key={index}
                            >
                              {support}
                            </span>
                          ))}

                        {!isExpanded && (
                          <button
                            className="px-3 py-2 text-xs ml-3 font-semibold text-black rounded-2xl mr-2 "
                            onClick={handleToggle}
                          >
                            Show All
                          </button>
                        )}
                        {isExpanded && (
                          <>
                            {currentUser.support_needed
                              .slice(2)
                              .map((support, index) => (
                                <span
                                  className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                                  key={index + 6}
                                >
                                  {support}
                                </span>
                              ))}
                            <button
                              className="px-3 py-2 text-xs ml-3 font-semibold text-black rounded-2xl mr-2"
                              onClick={handleToggle}
                            >
                              Show Less
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      currentUser.support_needed.map((support, index) => (
                        <span
                          className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                          key={index}
                        >
                          {support}
                        </span>
                      ))
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <>
          <div className="flex flex-col justify-start items-start md:ml-6 md:-mt-10 mt-16 mb-4 ml-5">
            <p className="md:hidden max-w-[96%] mt-6">
              Get more intros to interesting people automatically. Check New
              Intros every Monday and Thursday
              <div className="mt-2">
                <span>Your strongest skills are </span>
                <span className="block mt-3">
                  {/* {currentUser.strongest_skills.join(", ") || "Not Available"}{" "} */}
                  {currentUser.strongest_skills.map((skill, index) => {
                    return (
                      <span
                        className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                        key={index}
                      >
                        {skill}
                      </span>
                    );
                  })}
                </span>
                <br />
                <div>
                  <span>and you need support for </span>

                  <span className="mt-3 flex  flex-wrap gap-y-2 ">
                    {currentUser.support_needed.length > 2 ? (
                      <>
                        {currentUser.support_needed
                          .slice(0, 2)
                          .map((support, index) => (
                            <span
                              className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                              key={index}
                            >
                              {support}
                            </span>
                          ))}

                        {!isExpanded && (
                          <button
                            className="px-3 py-2 text-xs ml-3 font-semibold text-black rounded-2xl mr-2 "
                            onClick={handleToggle}
                          >
                            Show All
                          </button>
                        )}
                        {isExpanded && (
                          <>
                            {currentUser.support_needed
                              .slice(2)
                              .map((support, index) => (
                                <span
                                  className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                                  key={index + 6}
                                >
                                  {support}
                                </span>
                              ))}
                            <button
                              className="px-3 py-2 text-xs ml-3 font-semibold text-black rounded-2xl mr-2"
                              onClick={handleToggle}
                            >
                              Show Less
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      currentUser.support_needed.map((support, index) => (
                        <span
                          className="px-3 py-2 text-xs ml-3 bg-skincolor/40 text-black rounded-2xl mr-2"
                          key={index}
                        >
                          {support}
                        </span>
                      ))
                    )}
                  </span>
                </div>
              </div>
            </p>

            <div className="flex justify-start md:-ml-1 md:mt-6 mb-2 -ml-2">
              {renderPagination()}
            </div>
          </div>

          <div className="flex justify-center md:justify-start  w-full mb-6 md:mt-0 px-3 md:px-0 md:-ml-4">
            <div className="md:ml-6 w-full md:w-[70%] 2xl:w-[75%]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                {displayList()}
              </div>
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default IntroAI;
